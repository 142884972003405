import React from 'react';
import UserAvatar from 'react-user-avatar';
import AccessManager from '../../lib/AccessManager';
import MultiAvatar from './MultiAvatar';
import Tag from '../lib/Tag';
import CheckboxWithLabel from './CheckboxWithLabel';
import Constants from '../Constants';
import TextOverflowWithTooltip from './TextOverflowWithTooltip';

const ListShareCellFactory = (accessesByItemId, showAccessModal, user, size = 50) => {
  const ListShareCell = (cellProps) => {
    if (accessesByItemId === undefined || !user) {
      return { cell: <span>Loading...</span>, sortValue: 0 };
    }
    const accessObj = new AccessManager(cellProps.row.original.id, accessesByItemId, user);
    if (!accessObj.isShared()) {
      return {
        cell: (
          <span style={{ color: 'red' }} className="mtm is-flex">
            Private
          </span>
        ),
        sortValue: 0,
      };
    }
    const content = (
      <div className="plm">
        <MultiAvatar
          avatars={accessObj.bigListSharedColumnAvatars()}
          title={accessObj.bigListSharedColumnText()}
          size={size}
        />
      </div>
    );
    const cell = (
      <a href="#dummy" onClick={(e) => showAccessModal(e, cellProps.row.original, accessObj)}>
        {content}
      </a>
    );
    return {
      cell,
      sortValue: accessObj.bigListSharedColumnAvatars().length,
    };
  };
  return ListShareCell;
};

const RoleCell = (cellProps) => {
  if (!cellProps.row.original.roles) {
    return '--';
  }
  return (
    <div className="flex gap-2 flex-wrap">
      {cellProps.row.original.roles.map((role) => {
        return <Tag key={role} label={Constants.ROLE_DISPLAY_MAPPING[role]} />;
      })}
    </div>
  );
};

const GroupCellFactory = (groupIdsToNames) => {
  const GroupCell = (cellProps) => {
    if (!cellProps.row.original.group_ids) {
      return '--';
    }
    return (
      <div className="flex gap-2 flex-wrap">
        {groupIdsToNames(cellProps.row.original.group_ids).map((groupName) => {
          return <Tag key={groupName} label={groupName} />;
        })}
      </div>
    );
  };
  return GroupCell;
};

const NameCell = (cellProps) => {
  return (
    <span className="is-flex is-vertical-centered pvs">
      <UserAvatar
        colors={Constants.AVATAR_COLORS}
        size={32}
        name={cellProps.getValue()}
        src={cellProps.row.original.photo_url}
        className="mrm has-text-white"
      />
      {cellProps.getValue()}
    </span>
  );
};

const DynamicContentTypeCell = (cellProps) => {
  return (
    <span className="is-flex is-vertical-centered">
      <img
        src={
          Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[cellProps.getValue()]
            ? Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[cellProps.getValue()].icon
            : null
        }
        width="32px"
        alt="Dynamic Content Type"
        className="mrm"
      />
      {Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[cellProps.getValue()]
        ? Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[cellProps.getValue()].text
        : null}
    </span>
  );
};

const CheckboxCellFactory = (checkboxStates, onChangeFactory) => {
  const CheckboxCell = (cellProps) => {
    const checkboxState = checkboxStates[cellProps.row.original.id.toString()];
    if (!checkboxState) {
      return null;
    }
    const checked = checkboxState['checked'];
    const disabled = checkboxState['disabled'];
    const tooltip = disabled ? 'request edit permission to edit this item' : '';
    return (
      <div className="checkbox-container pln checkbox-cell">
        <CheckboxWithLabel
          tooltip={tooltip}
          checked={checked}
          disabled={disabled}
          id={`dc_checkbox_${cellProps.row.original.id}`}
          label="&nbsp;"
          onChange={onChangeFactory(cellProps.row.original.id)}
        />
      </div>
    );
  };
  return CheckboxCell;
};

const ContentShortTypeCell = (cellProps) => (
  <span className="is-flex is-vertical-centered">
    <img
      src={Constants.DYNAMIC_CONTENT_TYPE_DISPLAY[cellProps.getValue()]?.icon}
      width="32px"
      alt="Dynamic Content Type"
      className="mrm"
    />
  </span>
);

const DataSourceTypeCell = (cellProps) => {
  // cellProps will either be a CellProps object if used in a table cell,
  // or { value: dataSourceType } if used from FormHeader.
  const cellValue = cellProps.getValue ? cellProps.getValue() : cellProps.value;

  return (
    <span className="is-flex is-vertical-centered">
      {cellValue && Constants.DATA_SOURCE_TYPE_DISPLAY[cellValue] && (
        <img
          src={Constants.DATA_SOURCE_TYPE_DISPLAY[cellValue].icon}
          width="32px"
          alt="Data Source Type"
          className="mrs"
        />
      )}
    </span>
  );
};

const InputTypeCell = (cellProps) => {
  // cellProps will either be a CellProps object if used in a table cell,
  // or { value: dataSourceType } if used from FormHeader.
  const cellValue = cellProps.getValue ? cellProps.getValue() : cellProps.value;

  return (
    <span className="is-flex is-vertical-centered">
      <img
        src={Constants.INPUT_TYPES_FOR_DISPLAY[cellValue] ? Constants.INPUT_TYPES_FOR_DISPLAY[cellValue].icon : null}
        width="32px"
        alt="Parameter Type"
        className="mrm"
      />
    </span>
  );
};

const ContentNameCell = (cellProps) => {
  return <TextOverflowWithTooltip className="text-overflow-ellipsis max-w-3xl" text={cellProps.getValue()} />;
};

const DataSourceCell = (cellProps) => {
  if (cellProps.getValue() !== '--') {
    return (
      <span className="is-flex min-width-0 is-vertical-centered">
        <img
          src={
            Constants.DATA_SOURCE_TYPE_DISPLAY[cellProps.row.original.query_obj.data_source.type]
              ? Constants.DATA_SOURCE_TYPE_DISPLAY[cellProps.row.original.query_obj.data_source.type].icon
              : null
          }
          width="32px"
          className="mrm"
          alt="Data Source Type"
        />
        <TextOverflowWithTooltip
          className="text-overflow-ellipsis max-w-3xl"
          text={cellProps.row.original.query_obj.data_source.name}
        />
      </span>
    );
  }

  return '--';
};

const getDataSourceName = (content) => {
  if (
    content.dynamic_content_method !== Constants.DynamicContentMethods.STATIC &&
    content.query_obj &&
    content.query_obj.data_source &&
    content.query_obj.data_source.name
  ) {
    return content.query_obj.data_source.name;
  }

  return '--';
};

export {
  ListShareCellFactory,
  RoleCell,
  GroupCellFactory,
  NameCell,
  CheckboxCellFactory,
  DynamicContentTypeCell,
  ContentNameCell,
  ContentShortTypeCell,
  DataSourceTypeCell,
  InputTypeCell,
  DataSourceCell,
  getDataSourceName,
};
