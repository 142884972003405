import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import utils from '../../lib/utils';
import UserModal from './UserModal';
import UsersHeader from './UsersHeader';
import { Container } from 'react-bulma-components';
import FullPageList from '../shared/FullPageList';
import { NameCell, RoleCell, GroupCellFactory } from '../shared/FullPageListCells';
import SmallLoader from '../shared/SmallLoader';
import { mapUsersDispatchToProps } from '../../redux/users/dispatchers';
import { mapDispatchToProps as mapGroupsDispatchToProps } from '../../redux/groups/dispatchers';
import { mapEnterpriseUsersStateToProps } from '../../redux/users/stateMappers';
import { mapGroupStateToProps } from '../../redux/groups/stateMappers';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import Constants from '../Constants';
import WithInstantSearch from '../shared/search/WithInstantSearch';
import ComponentWithHitsAndPagination from '../shared/search/ComponentWithHitsAndPagination';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { MProductUpsell } from '../shared/Alerts';
import API from '../../lib/api';
import ReactDOMServer from 'react-dom/server';
import UpsellGenericModal from '../payments/UpsellGenericModal';
import teams from '../../lib/teams';
import withUserContext from '../shared/WithUserContext';
import BulkUserUploadModal from './BulkUserUploadModal';
import { createColumnHelper } from '@tanstack/react-table';
import localeDateFormatterUtils from 'lib/localeDate';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirstLoad: true,
      pagination: {},
    };
    this.groupsNameMap = {};
  }

  componentDidMount() {
    this.props.fetchGroupsIfNeeded().then(this._generateGroupsNameMap);
    this.props.fetchEnterpriseUsersIfNeeded().then(() => this.setState({ isFirstLoad: false }));
  }

  _generateGroupsNameMap = () => {
    this.groupsNameMap = {};
    this.props.groups.forEach((group) => (this.groupsNameMap[group.id] = group.name));
  };

  render() {
    if (this.state.isFirstLoad || this.props.isFetching) {
      return (
        <div>
          <SmallLoader />
        </div>
      );
    }
    return <div>{this.renderUserList()}</div>;
  }

  renderUserList() {
    const groupIdsToNames = (groupIds) => {
      return groupIds.map((groupId) => this.groupsNameMap[groupId]);
    };

    const columnHelper = createColumnHelper();

    const columns = [
      columnHelper.accessor((d) => (d.name ? d.name : d.email), {
        id: 'name',
        header: utils.tableHeader('Name'),
        cell: NameCell,
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: utils.tableHeader('Email'),
      }),
      columnHelper.accessor((d) => (d.title ? d.title : '--'), {
        id: 'title',
        header: utils.tableHeader('Title'),
      }),
      columnHelper.accessor((d) => (d.roles ? d.roles.join(', ') : '--'), {
        id: 'roles',
        header: utils.tableHeader('Roles'),
        cell: RoleCell,
      }),
      columnHelper.accessor((d) => (d.group_ids ? groupIdsToNames(d.group_ids).join(', ') : ''), {
        id: 'groups',
        header: utils.tableHeader('Groups'),
        cell: GroupCellFactory(groupIdsToNames),
      }),
      columnHelper.accessor(
        (d) => {
          const locale = localeDateFormatterUtils.getUserLocale();
          const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
          return moment(d.created_on).format(localeDateFormat);
        },
        {
          id: 'created_on',
          header: 'Provisioned Date',
        },
      ),
      columnHelper.accessor((d) => (d.active ? 'Active' : 'Inactive'), {
        id: 'status',
        header: utils.tableHeader('Status'),
        meta: {
          width: '100px',
        },
      }),
    ];

    return (
      <React.Fragment>
        <Container className="is-fluid mbl">
          <UsersHeader
            onNewUserClick={this.onNewUserClick}
            onCSVBulkUserClick={this.onCSVBulkUserClick}
            enterprise={this.props.enterprise}
            searchAttributes={this.props.searchAttributes}
            searchState={this.props.searchState}
          />
          {this.props.isSearching ? (
            <ComponentWithHitsAndPagination
              WrappedComponent={FullPageList}
              onRowClick={this.onRowClick}
              columns={columns}
            />
          ) : (
            <FullPageList
              entitiesToRender={this.props.users}
              columns={columns}
              fetchItems={this.fetchItems}
              onRowClick={this.onRowClick}
              pagination={this.props.pagination}
              loading={this.props.isFetching}
            />
          )}
        </Container>
        <UserModal
          key={this.props.selectedUser ? this.props.selectedUser.id : -1}
          selectedUser={this.props.selectedUser}
          roles={this.props.roles}
          updateEnterpriseCounts={this.props.updateEnterpriseCounts}
          canAddUser={this.canAddUser}
          showUserLimitWarning={this.showUserLimitWarning}
        />
        <BulkUserUploadModal
          roles={this.props.roles}
          updateEnterpriseCounts={this.props.updateEnterpriseCounts}
          canAddUser={this.canAddUser}
          showUserLimitWarning={this.showUserLimitWarning}
        />
      </React.Fragment>
    );
  }

  onRowClick = (userId) => {
    if (!this.props.users || this.props.users.length === 0) {
      return null;
    }

    this.props.selectEnterpriseUser(userId).then(() => this.props.openModal('userModal'));
  };

  onModalClose = () => {
    this.props.clearSelectedEnterpriseUser();
    this.props.closeModal();
  };

  fetchNoUsersLeftHtml = () => {
    const planName = Constants.MATIK_TIERS[this.props.enterprise.billing.plan_id].display_name;

    return ReactDOMServer.renderToStaticMarkup(
      <UpsellGenericModal
        featureHeader={`Get more Users with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
        featureDescription={`Your team has already added the maximum number of users
              allowed on the ${planName} plan. Please upgrade to add more users.`}
      />,
    );
  };

  canAddUser = () => {
    if (this.props.flags.enableUsersLimitCheck === false) {
      return true;
    }
    if (
      this.props.enterprise.billing.plan_id === Constants.MATIK_TIERS.matik_team.tier_id &&
      (this.props.enterprise.billing.subscription_status === Constants.SUBSCRIPTION_STATUSES.active ||
        this.props.enterprise.billing.subscription_status === Constants.SUBSCRIPTION_STATUSES.trialing)
    ) {
      return this.props.enterprise.users_count < Constants.MATIK_TIERS.matik_team.userLimit;
    }
    return true;
  };

  onNewUserClick = (e) => {
    e.preventDefault();
    if (this.canAddUser()) {
      this.props.clearSelectedEnterpriseUser();
      this.props.openModal('userModal');
    } else {
      this.showUserLimitWarning();
    }
  };

  onCSVBulkUserClick = (e) => {
    e.preventDefault();
    if (this.canAddUser()) {
      this.props.clearSelectedEnterpriseUser();
      this.props.openModal('bulkUserUploadModal');
    } else {
      this.showUserLimitWarning();
    }
  };

  showUserLimitWarning = () => {
    const noUsersLeftHtml = this.fetchNoUsersLeftHtml();
    API.track('enterprise_upsell_shown', { from: 'no_users_remaining' });
    MProductUpsell(`${noUsersLeftHtml}`, false, (result) => {
      if (!result.dismiss) {
        API.track('discover_matik_enterprise_click', { from: 'no_users_remaining' });
        const demoUrl = teams.buildRequestDemoUrl(
          this.props.userContext.user,
          'seat_limit',
          this.props.userContext.user.enterprise.trial_days_remaining,
        );
        window.open(demoUrl, '_blank');
      }
    });
  };

  fetchItems = (pageIndex, sort) => {
    const offset = pageIndex * Constants.PAGE_SIZE;
    this.props.fetchEnterpriseUsersIfNeeded(offset, Constants.PAGE_SIZE, sort, this.props.pagination.count);
  };
}

Users.propTypes = {
  clearSelectedEnterpriseUser: PropTypes.func,
  enterprise: PropTypes.object,
  selectEnterpriseUser: PropTypes.func,
  fetchEnterpriseUsersIfNeeded: PropTypes.func,
  fetchGroupsIfNeeded: PropTypes.func,
  isFetching: PropTypes.bool,
  isSearching: PropTypes.bool,
  pagination: PropTypes.object,
  roles: PropTypes.array,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedUser: PropTypes.object,
  updateEnterpriseCounts: PropTypes.func,
  users: PropTypes.array,
  groups: PropTypes.array,
  flags: PropTypes.object,
  history: PropTypes.object,
  userContext: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return Object.assign(
    {},
    mapGroupStateToProps(state, ownProps),
    mapEnterpriseUsersStateToProps(state, ownProps),
    mapUiStateToProps(state),
  );
}

function mapDispatchToProps(dispatch) {
  return Object.assign(
    {},
    mapGroupsDispatchToProps(dispatch),
    mapUsersDispatchToProps(dispatch),
    mapUiDispatchToProps(dispatch),
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withUserContext(withRouter(WithInstantSearch(withLDConsumer()(Users)))));
