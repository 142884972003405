import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import dismissIcon from '../../images/x_shape.svg';
import infoIcon from '../../images/info_circle.svg';
import successIcon from '../../images/success.svg';
import warningIcon from '../../images/warning_circle.svg';
import errorIcon from '../../images/error_circle.svg';
import Button from '../lib/Button';

class Banner extends Component {
  render() {
    let icon;
    let className = `${this.props.className} ` || '';
    switch (this.props.bannerType) {
      case 'info':
        icon = <img src={infoIcon} alt="info" className="icon" />;
        className += 'info';
        break;
      case 'success':
        icon = <img src={successIcon} alt="success" className="icon" />;
        className += 'success';
        break;
      case 'warning':
        icon = <img src={warningIcon} alt="warning" className="icon" />;
        className += 'warning';
        break;
      case 'error':
        icon = <img src={errorIcon} alt="error" className="icon" />;
        className += 'error';
        break;
      default:
        icon = null;
        className += 'default';
        break;
    }
    className = `banner ${className}`;

    const mainText =
      this.props.alignment === 'center' ? (
        <div className="flex items-center justify-center">
          {<div className="flex items-center">{icon}</div>}
          <div className="flex gap-1">
            <span className="centered-banner-text">{this.props.text}</span>
            {this.props.sublineText && <span className="subline block">{this.props.sublineText}</span>}
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-4">
          {<div className="flex items-center">{icon}</div>}
          <div className="flex flex-col w-full">
            <div className="break-words">
              {this.props.text}
              <b>{this.props.timeElapsedText} </b>
              {this.props.limitMessage}
            </div>
            {this.props.sublineText && <div className="subline">{this.props.sublineText}</div>}
          </div>
        </div>
      );

    return (
      <div className={className}>
        <div
          className={`flex items-center gap-4 ${
            this.props.alignment === 'center' ? 'justify-center' : 'justify-between'
          }`}
        >
          {mainText}
          <div className="flex items-center gap-4">
            {this.props.secondaryButtonLabel && this.props.onSecondaryButtonClick && (
              <div className="whitespace-nowrap">
                <Button category="secondary" onClick={this.props.onSecondaryButtonClick}>
                  {this.props.secondaryButtonLabel}
                </Button>
              </div>
            )}
            {this.props.primaryButtonLabel && this.props.onPrimaryButtonClick && (
              <div className="whitespace-nowrap">
                <Button onClick={this.props.onPrimaryButtonClick}>{this.props.primaryButtonLabel}</Button>
              </div>
            )}
            {this.props.onDismiss && (
              <div className="min-w-4">
                <a href="#" onClick={this.props.onDismiss}>
                  <img src={dismissIcon} alt="dismiss" className="min-w-2" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Banner.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  sublineText: PropTypes.any,
  bannerType: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  onSecondaryButtonClick: PropTypes.func,
  primaryButtonLabel: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  onDismiss: PropTypes.func,
  alignment: PropTypes.string,
  timeElapsedText: PropTypes.string,
  limitMessage: PropTypes.string,
};

export const toastBanner = (banner) => {
  const toastId = { current: null };
  let newProps = Object.assign({}, banner.props);
  newProps.onDismiss = () => {
    if (banner.props.onDismiss) {
      banner.props.onDismiss();
    }
    toast.dismiss(toastId.current);
  };
  let toastOptions = { type: 'default', containerId: 'banner' };
  if (banner.props.bannerType === 'error') {
    toastOptions['autoClose'] = false;
  }
  toastId.current = toast(<Banner {...newProps} />, toastOptions);
};

export default Banner;
