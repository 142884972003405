import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bulma-components';
import { ReactComponent as ChatIcon } from '../../../svg/chat_icon.svg';
import { ReactComponent as EmailIcon } from '../../../svg/email_icon.svg';
import { ReactComponent as HelpIcon } from '../../../svg/help_icon.svg';
import useOnClickOutside from 'lib/hooks/useOnClickOutside';

function HelpMenu(props) {
  const { show, toggleHelp } = props;
  const ref = useRef();
  useOnClickOutside(ref, (e) => toggleHelp(e, false));

  return (
    <Navbar.Dropdown className={show ? 'is-block is-right' : 'is-right'}>
      <div ref={ref}>
        <div className="pbs vertical-center border-bottom">
          <a onClick={() => window.zE('messenger', 'open')} className="has-text-dark navbar-item full-width">
            <ChatIcon className="help-nav-icon" />
            <span className="plm">Chat with us</span>
          </a>
        </div>
        <div className="pbs pts vertical-center border-bottom">
          <a href="mailto:support@matik.io" className="has-text-dark navbar-item full-width">
            <EmailIcon className="help-nav-icon" />
            <span className="plm">Email us</span>
          </a>
        </div>
        <div className="pts vertical-center">
          <a
            href="https://help.matik.io"
            target="_blank"
            rel="noreferrer"
            className="has-text-dark navbar-item full-width"
          >
            <HelpIcon className="help-nav-icon" />
            <span className="plm">Help Center</span>
          </a>
        </div>
      </div>
    </Navbar.Dropdown>
  );
}

HelpMenu.propTypes = {
  show: PropTypes.bool,
  toggleHelp: PropTypes.func,
};

export default HelpMenu;
