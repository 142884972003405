import React from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useAccesses from 'lib/hooks/useAccess';

/**
 * For debugging purposes, show the accesses on this item. Note that this is not something that will be
 * seen by customers, only those with the showAccessOnUnchangeableItems flag enabled. i.e. devs.
 */
export default function AccessesDebugView(props) {
  const { showAccessOnUnchangeableItems } = useFlags();
  if (!showAccessOnUnchangeableItems) {
    return null;
  }
  return <Accesses {...props} />;
}

function Accesses({ className, itemType, itemId }) {
  const { data: accessesByItemId } = useAccesses(itemType);

  return (
    <div className={className || ''}>
      <div className="flex">
        <div className="mr-1 font-bold">Accesses &mdash;</div>
        <div className="flex gap-3">
          {accessesByItemId?.[itemId]?.map((access) => (
            <div key={access.id}>
              {access.accessor.name} ({access.accessor.type}): {access.permission}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
Accesses.propTypes = {
  itemType: PropTypes.string,
  itemId: PropTypes.number,
  className: PropTypes.string,
};
