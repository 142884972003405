import React, { Component, Fragment } from 'react';
import FullPageList from '../../shared/FullPageList';
import BulkActionBar from './BulkActionBar';
import PropTypes from 'prop-types';
import AccessManager from '../../../lib/AccessManager';
import Constants from '../../Constants';
import { UserContext } from '../../UserContext';
import { cloneDeep } from 'lodash';
import { CheckboxCellFactory } from '../../shared/FullPageListCells';
import { createColumnHelper } from '@tanstack/react-table';

class FullPageListWithBulkActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxes: this.getCheckboxState({}),
    };
  }

  componentDidUpdate(prevProps) {
    // accessesByItemId loads asynchronously, so you just have to sit and wait for it to appear.
    if (
      prevProps.accessesByItemId !== this.props.accessesByItemId ||
      (this.props.accessesByItemId &&
        !!Object.keys(this.props.accessesByItemId).length &&
        !Object.keys(this.state.checkboxes).length)
    ) {
      this.setState((prevState) => {
        const newCheckboxState = this.getCheckboxState(prevState.checkboxes);
        return { checkboxes: newCheckboxState };
      });
    }
  }

  render() {
    let { columns, ...otherProps } = this.props;

    const columnHelper = createColumnHelper();

    columns = [
      columnHelper.display({
        id: 'checkbox',
        cell: CheckboxCellFactory(this.state.checkboxes, this.onCheckboxChangeFactory),
        meta: {
          width: '32px',
        },
        size: 32,
        enablePinning: true,
        pin: 'left',
      }),
      ...this.props.columns,
    ];

    return (
      <Fragment>
        <BulkActionBar
          itemsOnPage={this.props.entitiesToRender}
          checkboxes={this.state.checkboxes}
          entityType={this.props.entityType}
          onMasterCheckboxChange={this.onMasterCheckboxChange}
          bulkActionElements={this.props.bulkActionComponents.map((bulkActionComponent) =>
            bulkActionComponent({
              selectedItems: this.getSelectedItems(),
            }),
          )}
        />
        <FullPageList columns={columns} {...otherProps} />
      </Fragment>
    );
  }

  getCheckboxState(previousCheckboxes) {
    // TODO (Zak): I don't understand why context could be undefined, but it is: maybe something about the async nature
    // of react/js
    if (!this.props.accessesByItemId || !this.context) {
      return {};
    }
    const newCheckboxState = Object.fromEntries(
      Object.keys(this.props.accessesByItemId).map((itemIdStr) => {
        const accesses = new AccessManager(parseInt(itemIdStr, 10), this.props.accessesByItemId, this.context.user);
        let disabled = !accesses.can(Constants.PERMISSIONS.edit.value);
        const checked = previousCheckboxes[itemIdStr] ? previousCheckboxes[itemIdStr].checked : false;
        return [itemIdStr, { checked: checked, disabled: disabled }];
      }),
    );
    return newCheckboxState;
  }

  onCheckboxChangeFactory = (itemID) => {
    const onChange = (e) => {
      e.persist();
      this.setState((prevState) => {
        let currentCheckboxes = cloneDeep(prevState.checkboxes);
        currentCheckboxes[itemID.toString()] = { checked: e.target.checked, disabled: e.target.disabled };
        return { checkboxes: currentCheckboxes };
      });
    };
    return onChange;
  };

  onMasterCheckboxChange = (checked, currentItemIds) => {
    this.setState((prevState) => {
      let newCheckboxes = cloneDeep(prevState.checkboxes);
      for (let itemId of currentItemIds) {
        const checkbox = prevState.checkboxes[itemId.toString()];
        if (checkbox && !checkbox.disabled) {
          newCheckboxes[itemId.toString()].checked = checked;
        }
      }
      return { checkboxes: newCheckboxes };
    });
  };

  getSelectedItems = () => {
    let selectedItems = [];
    for (let item of this.props.entitiesToRender) {
      const checkbox = this.state.checkboxes[item.id.toString()];
      if (checkbox && checkbox.checked) {
        selectedItems.push(item);
      }
    }
    return selectedItems;
  };
}

FullPageListWithBulkActions.propTypes = {
  ...BulkActionBar.propTypes,
  ...FullPageList.propTypes,
  accessesByItemId: PropTypes.object,
  bulkActionComponents: PropTypes.array,
};

FullPageListWithBulkActions.contextType = UserContext;

export default FullPageListWithBulkActions;
