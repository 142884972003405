import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import ModalHeader from 'components/lib/ModalHeader';
import ButtonGroup from 'components/lib/ButtonGroup';
import Icon from 'components/lib/Icon';
import Button from 'components/lib/Button';
import Connector from './Connector';
import { Select, MenuNoSearch } from 'components/shared/FormSelect';
import Constants from 'components/Constants';
import InputDynamicContent from 'components/producer/dynamicContent/InputDynamicContent';
import InputText from 'components/lib/InputText';
import {
  useCondition,
  useConditionMutator,
  useConditionTargetMutator,
  useSlideConditionTargets,
} from 'lib/hooks/useCondition';
import { isEqual } from 'lodash';
import { MConfirm } from 'components/shared/Alerts';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';
import AccessesDebugView from 'components/shared/AccessDebugView';

const ClausePropType = PropTypes.shape({
  dynamicContent: PropTypes.number,
  operator: PropTypes.string,
  operand: PropTypes.string,
});
const ConditionPropType = PropTypes.shape({
  clauses: PropTypes.arrayOf(ClausePropType),
});

const CONDITION_COLORS = [];
['200', '400', '500', '600', '700'].forEach((lum) => {
  ['green', 'yellow', 'red', 'blue', 'purple'].forEach((hue) => CONDITION_COLORS.push(`${hue}-${lum}`));
});

const ConditionForm = ({ conditionId, slideIds, onClose, onCreated }) => {
  /* condition model:
    name
    description
    icon_color
    conditions =
      {"conditions": [{"clauses": [{"dynamicContent": 105, "operator": "=", "operand": "X"}]}]}
  */

  const { data: savedCondition } = useCondition(conditionId);

  const { create, update, del } = useConditionMutator();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [conditions, setConditions] = useState({});

  const [isSaving, setIsSaving] = useState(false);

  const { toggleIsChanged } = mapDispatchToProps(useDispatch());

  useEffect(() => {
    if (savedCondition) {
      setName(savedCondition.name);
      setDescription(savedCondition.description);
      setConditions(savedCondition.conditions);
    }
  }, [savedCondition?.name, savedCondition?.description, JSON.stringify(savedCondition?.conditions)]);

  const initialState = savedCondition ?? { name: '', description: '', conditions: {} };
  const hasChanges =
    initialState.name !== name ||
    initialState.description !== description ||
    !isEqual(initialState.conditions, conditions);

  useEffect(() => {
    toggleIsChanged(hasChanges);
  }, [hasChanges]);

  const handleDelete = () => {
    const slideTargetCount = (savedCondition.targets.slide || []).length;
    const message = `Are you sure you want to delete this condition? 
    Deleting it will remove it from ${slideTargetCount} slide${slideTargetCount === 1 ? '' : 's'}.`;

    MConfirm('Delete', message, 'warning', (confirmed) => {
      if (confirmed) {
        del(savedCondition).then(() => onClose());
      }
    });
  };
  const handleSave = () => {
    setIsSaving(true);
    if (conditionId) {
      const updateData = { ...savedCondition, name, description, conditions };
      update(updateData).finally(() => setIsSaving(false));
    } else {
      const insertData = {
        name,
        description,
        conditions,
        // assign a color at random
        icon_color: CONDITION_COLORS[new Date().getTime() % CONDITION_COLORS.length],
      };
      create(insertData)
        .then(addToSlides)
        .then(onCreated)
        .finally(() => setIsSaving(false));
    }
  };

  const { upsertSlides: upsertSlideConditions } = useConditionTargetMutator();
  const targetResultsById = useSlideConditionTargets(slideIds || []);

  const addToSlides = (condition) => {
    // OR the new condition to the given slides

    const bySlide = {};
    slideIds.forEach((slideId) => {
      const slideTarget = targetResultsById[slideId].data;
      const ors = slideTarget?.condition_clauses?.or || [];
      const updatedConditions = {
        or: [...ors, { and: [condition.id] }],
      };
      bySlide[slideId] = updatedConditions;
    });

    return upsertSlideConditions(bySlide).then(() => condition);
  };

  const getSaveStatus = () => {
    if (name && conditions.conditions?.length > 0 && conditions.conditions.every(isComplete)) {
      // If we're adding this condition to selected slides, ensure the targets have been loaded.
      let canSave = true;
      if (slideIds?.length > 0) {
        canSave = Object.values(targetResultsById).every((result) => !result.isPending);
      }
      return {
        canSave,
      };
    } else {
      return {
        canSave: false,
        message: 'You must specify a name and populate all conditions with valid criteria to save.',
      };
    }
  };
  const { canSave, message: cannotSaveMessage } = getSaveStatus();

  return (
    <div className="flex flex-col h-full">
      <ModalHeader iconName="condition_arrow" title={name} onClose={onClose} />
      <AccessesDebugView className="mt-4 ml-5" itemType="condition" itemId={conditionId} />
      <div className="!p-6 flex flex-col gap-[30px] grow overflow-y-auto min-h-0">
        <div className="border border-grey-300 !p-6 rounded flex flex-col gap-4">
          <LabeledInput label="Name" value={name} onChange={setName} />
          <LabeledInput label="Description" value={description} onChange={setDescription} />
        </div>
        <ConditionGroups conditions={conditions} onChange={setConditions} />
      </div>
      <div className="!py-5 !px-6 border-t border-grey-300 flex items-center">
        <ButtonGroup width="hug" gap={3}>
          {conditionId && (
            <Button category="secondary" onClick={handleDelete}>
              <div className="flex gap-2 items-center">
                <Icon name="trash_can" size={16} theme="regular" />
                <span>Delete Condition</span>
              </div>
            </Button>
          )}
          <Button category="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
        <div className="ml-auto">
          <Button
            status={isSaving ? 'loading' : canSave && hasChanges ? 'default' : 'disabled'}
            onClick={handleSave}
            data-tooltip-id="matik-tooltip"
            data-tooltip-content={cannotSaveMessage}
          >
            Save Condition
          </Button>
        </div>
      </div>
      <Prompt when={hasChanges} message="Are you sure you want to navigate away? There are unsaved changes." />
    </div>
  );
};
ConditionForm.propTypes = {
  conditionId: PropTypes.number,
  slideIds: PropTypes.arrayOf(PropTypes.number),
  onClose: PropTypes.func,
  /** Fired after a new condition was created and saved to the db */
  onCreated: PropTypes.func,
};
export default ConditionForm;

const LabeledInput = ({ label, value, onChange }) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="text-grey-600">{label}</div>
      <div>
        <InputText value={value} onChange={onChange} />
      </div>
    </div>
  );
};
LabeledInput.propTypes = {
  label: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

const ConditionGroups = ({ conditions, onChange }) => {
  // conditions like  {"conditions": [{"clauses": [{"dynamicContent": 105, "operator": "=", "operand": "X"}]}]}
  const handleGroupAdd = () => {
    onChange({ conditions: [...(conditions.conditions || []), { clauses: [{}] }] });
  };
  const handleGroupDelete = (index) => {
    const updated = conditions.conditions.toSpliced(index, 1);
    onChange({ conditions: updated });
  };
  const handleGroupUpdate = (index, group) => {
    if (group.clauses?.length > 0) {
      const updated = [...conditions.conditions];
      updated[index] = group;
      onChange({ conditions: updated });
    } else {
      handleGroupDelete(index);
    }
  };
  return (
    <div className="flex flex-col gap-4">
      {conditions.conditions?.map((condition, index) => (
        <div key={index} className="flex flex-col gap-2 border border-grey-300 !p-6 rounded">
          <div className="flex items-center gap-1.5 text-matik-black group">
            <div className="rounded-md border border-grey-300 bg-grey-50 py-1.5 px-2.5 leading-none">
              {index === 0 ? 'If' : 'Or'}
            </div>
            <div className="grow">Keep this slide if these conditions are true</div>
            <div className="group-hover:visible invisible">
              <Button category="tertiary" onClick={() => handleGroupDelete(index)}>
                <Icon name="trash_can" size={16} />
              </Button>
            </div>
          </div>
          <div>
            <ConditionGroup
              key={index}
              condition={condition}
              onChange={(newValue) => handleGroupUpdate(index, newValue)}
            />
          </div>
        </div>
      ))}
      <div>
        <Button category="secondary" onClick={handleGroupAdd}>
          <div className="flex items-center gap-1">
            <Icon name="add_circle" size={20} /> <div>Add Condition Group</div>
          </div>
        </Button>
      </div>
    </div>
  );
};
ConditionGroups.propTypes = {
  conditions: PropTypes.shape({
    conditions: PropTypes.arrayOf(ConditionPropType),
  }),
  onChange: PropTypes.func,
};

function isComplete(conditionGroup) {
  return conditionGroup?.clauses?.every((clause) => clause.dynamicContent > 0 && !!clause.operator);
}

const ConditionGroup = ({ condition, onChange }) => {
  // group like {"clauses": [{"dynamicContent": 105, "operator": "=", "operand": "X"}]}]
  const handleAdd = () => {
    onChange({ clauses: [...(condition.clauses || []), {}] });
  };
  const handleClauseChange = (index, clause) => {
    const updated = [...condition.clauses];
    updated[index] = clause;
    onChange({ clauses: updated });
  };
  const handleRemove = (index) => {
    onChange({ clauses: condition.clauses.toSpliced(index, 1) });
  };
  return (
    <div className="grid grid-cols-[auto_1fr]">
      {condition.clauses?.map((clause, index) => (
        <Fragment key={index}>
          <div className="flex">
            <Connector
              className="w-9 h-full"
              right
              down
              roundedDownRight={index === 0}
              label={index > 0 ? 'And' : null}
              up={index > 0}
              labelClassName="w-9"
            />
            <Connector className="w-3 h-full" right left />
          </div>
          <div className="py-1">
            <ConditionGroupItem
              clause={clause}
              onChange={(newValue) => handleClauseChange(index, newValue)}
              onRemove={() => handleRemove(index)}
            />
          </div>
        </Fragment>
      ))}
      {condition.clauses?.length > 0 && (
        <button className="w-9" onClick={handleAdd}>
          <Connector up label={<Icon size={16} name="plus" />} labelClassName="w-9 text-matik-black" />
        </button>
      )}
    </div>
  );
};
ConditionGroup.propTypes = {
  condition: ConditionPropType,
  conditionsById: PropTypes.object,
  onChange: PropTypes.func,
};

const ConditionGroupItem = ({ clause, onChange, onRemove }) => {
  const { dynamicContentById, isLoading, isError } = useAllDynamicContentById();
  if (isLoading) {
    return <Skeleton height="2rem" />;
  }
  if (isError) {
    return <div>There was an error loading your content, please refresh the page.</div>;
  }

  const handleDynamicContentChange = (value) => {
    onChange({ ...clause, dynamicContent: value.id });
  };
  const handleOperandChange = (value) => {
    onChange({ ...clause, operand: value });
  };
  const handleOperatorChange = (value) => {
    onChange({ ...clause, operator: value });
  };

  const supported_operators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.conditions;
  const dynamicContent = dynamicContentById[clause.dynamicContent];
  return (
    <div className="flex gap-2 items-center">
      <div data-tooltip-content={dynamicContent?.name} data-tooltip-id="matik-tooltip" className="grow">
        <InputDynamicContent
          acceptedContentTypes={[Constants.DynamicContentTypes.TEXT]}
          content={dynamicContent}
          allDynamicContentNamesById={dynamicContentById}
          onChange={handleDynamicContentChange}
        />
      </div>

      <div className="min-w-[70px]" data-tooltip-content={clause.operator} data-tooltip-id="matik-tooltip">
        <Select
          value={{ label: clause.operator, value: clause.operator }}
          onChange={(val) => handleOperatorChange(val.value)}
          componentsToAdd={{ Menu: MenuNoSearch }}
          styles={{
            menu: (styles) => ({ ...styles, minWidth: '110px' }),
          }}
          classNamePrefix="matik-select"
          options={supported_operators.map((op) => ({
            label: op,
            value: op,
          }))}
        ></Select>
      </div>
      {clause.operator !== 'is null' && clause.operator !== 'is not null' && (
        <div className="w-[160px]">
          <InputText value={clause.operand || ''} onChange={handleOperandChange} />
        </div>
      )}
      <Button category="secondary" onClick={onRemove} width="square" size="medium">
        <Icon name="trash_can" size={16} />
      </Button>
    </div>
  );
};
ConditionGroupItem.propTypes = {
  clause: ClausePropType,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};
