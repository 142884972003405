import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Form, Hero, Heading } from 'react-bulma-components';
import { find } from 'lodash';
import API from '../../lib/api';
import loading_icon from '../../images/loading.gif';
import FullPageList from '../shared/FullPageList';
import utils from '../../lib/utils';
import { ContentNameCell } from '../shared/FullPageListCells';
import ClientModal from './ClientModal';
import groups_empty_icon from '../../images/groups_empty.svg';
import ClientSecretModal from './ClientSecretModal';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import Button from '../lib/Button';
import { createColumnHelper } from '@tanstack/react-table';

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      isLoading: true,
      selectedClient: null,
      selectedSecret: null,
      users: [],
    };
  }

  componentDidMount() {
    API.getMultiple(['/enterprises/clients/', '/enterprises/users/?all=true'], (clientsResponse, usersResponse) =>
      this.setState({ clients: clientsResponse.data, users: usersResponse.data, isLoading: false }),
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ui.modal?.name !== this.props.ui.modal?.name && !this.props.ui.modal?.name) {
      this.setState({ selectedClient: null });
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Container className="has-text-centered">
          <img src={loading_icon} width="100rem" alt="loading" />
        </Container>
      );
    }

    let body = this.renderEmpty();
    if (this.state.clients.length > 0) {
      const columnHelper = createColumnHelper();

      const columns = [
        columnHelper.accessor('client_name', {
          id: 'name',
          header: utils.tableHeader('Name'),
          cell: ContentNameCell,
        }),
        columnHelper.accessor('client_id', {
          id: 'client_id',
          header: utils.tableHeader('Client ID'),
        }),
        columnHelper.accessor(
          (row) => {
            const user = find(this.state.users, (u) => u.id === row.user_id);
            return user.name ? user.name : user.email;
          },
          {
            id: 'user',
            header: utils.tableHeader('User'),
          },
        ),
      ];

      body = (
        <React.Fragment>
          <Form.Field kind="group" className="mbl" align="right">
            <Form.Control>
              <button className="button is-primary is-medium" onClick={this.onNewClientClick}>
                Add New Client
              </button>
            </Form.Control>
          </Form.Field>
          <FullPageList
            entitiesToRender={this.state.clients}
            columns={columns}
            onRowClick={this.onRowClick}
            loading={this.state.isLoading}
            hideAllPagination={true}
          />
        </React.Fragment>
      );
    }

    const selectedClientKey = this.state.selectedClient ? this.state.selectedClient.id : -1;

    return (
      <Container className="is-fluid">
        {body}
        <ClientModal
          key={selectedClientKey}
          onClose={this.onClientModalClose}
          selectedClient={this.state.selectedClient}
          users={this.state.users}
          onCreate={this.onClientCreate}
          onDelete={this.onClientDelete}
          onUpdate={this.onClientUpdate}
        />
        <ClientSecretModal onClose={this.onClientSecretModalClose} secret={this.state.selectedSecret} />
      </Container>
    );
  }

  onRowClick = (clientId) => {
    const client = find(this.state.clients, (c) => c.id === clientId);
    this.setState({ selectedClient: client });
    this.props.openModal('clientModal');
  };

  onClientModalClose = () => {
    this.props.closeModal();
  };

  onClientSecretModalClose = () => {
    this.setState({ selectedSecret: null });
    this.props.closeModal();
  };

  onNewClientClick = (e) => {
    e.preventDefault();
    this.props.openModal('clientModal');
  };

  onClientDelete = (deletedClientId) => {
    const updatedClients = this.state.clients.filter((c) => c.id !== deletedClientId);
    this.setState({ clients: updatedClients });
  };

  onClientCreate = (createdClient) => {
    const client_secret = createdClient.client_secret;
    createdClient.client_secret = null;
    const updatedClients = this.state.clients.slice();
    updatedClients.push(createdClient);
    this.setState({ clients: updatedClients, selectedSecret: client_secret });
    this.props.openModal('clientSecretModal');
  };

  onClientUpdate = (updatedClient) => {
    const updatedClients = [];
    this.state.clients.forEach((c) => {
      if (c.id === updatedClient.id) {
        updatedClients.push(updatedClient);
      } else {
        updatedClients.push(c);
      }
    });

    this.setState({ clients: updatedClients });
  };

  renderEmpty = () => {
    return (
      <Container className="is-fluid">
        <Hero>
          <Hero.Body>
            <Container className="has-text-centered">
              <Heading size={5} subtitle>
                <img width="256px" src={groups_empty_icon} alt="Empty Groups" />
              </Heading>
              <Heading size={4} className="has-text-grey-dark">
                No Clients
              </Heading>
              <Heading size={5} subtitle className="has-text-grey-dark">
                No clients yet, add your first one
              </Heading>
              <Button onClick={this.onNewClientClick}>Add New Client</Button>
            </Container>
          </Hero.Body>
        </Hero>
      </Container>
    );
  };
}

Clients.propTypes = {
  enterprise: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  ui: PropTypes.object,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(Clients);
