import React from 'react';
import PropTypes from 'prop-types';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ReactComponent as GreenCheckmark } from '../../svg/check_bullet.svg';
import FeatureComparisonHeader from './FeatureComparisonHeader';
import Constants from 'components/Constants';

const FeatureComparison = ({ userTier, onTierSelection, isTrialing }) => {
  return (
    <div className="feature-comparison">
      <FeatureComparisonHeader userTier={userTier} onTierSelection={onTierSelection} isTrialing={isTrialing} />
      <KeyFeatureTable />
      <PresentationAutomationTable />
      <WorkflowAutomationTable />
      <AdministrationTable />
      <SupportTable />
    </div>
  );
};

FeatureComparison.propTypes = {
  onTierSelection: PropTypes.func,
  userTier: PropTypes.string,
  isTrialing: PropTypes.bool,
};

const KeyFeatureTable = () => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('label'),
    columnHelper.accessor('teamValue'),
    columnHelper.accessor('enterpriseValue'),
  ];

  const keyFeatureTableData = [
    {
      label: 'Number of data source connections',
      teamValue: 'Unlimited',
      enterpriseValue: 'Unlimited',
    },
    {
      label: 'Connect to apps',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Connect to data warehouses',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Connect through REST APIs',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Number of dynamic content',
      teamValue: 'Unlimited',
      enterpriseValue: 'Unlimited',
    },
    {
      label: 'Number of templates',
      teamValue: 'Unlimited',
      enterpriseValue: 'Unlimited',
    },
    {
      label: 'Number of presentation generations',
      teamValue: '5 / account / month',
      enterpriseValue: 'Custom',
    },
    {
      label: 'Content search',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
  ];

  const keyFeatureTable = useReactTable({ columns, data: keyFeatureTableData, getCoreRowModel: getCoreRowModel() });

  return (
    <>
      <div className="feature-comparison-section-header">Key Features</div>
      <table className="feature-table">
        <tbody>
          {keyFeatureTable.getRowModel().rows.map((row) => (
            <tr className="feature-table-row" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td className="feature-table-cell" key={cell.id}>
                  {cell.renderValue()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const PresentationAutomationTable = () => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('label'),
    columnHelper.accessor('teamValue'),
    columnHelper.accessor('enterpriseValue'),
  ];

  const presentationAutomationTableData = [
    {
      label: 'Conditional slides',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Conditional content',
      teamValue: '',
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Slide looping',
      teamValue: '',
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Slide library',
      teamValue: '',
      enterpriseValue: <GreenCheckmark />,
    },
  ];

  const presentationAutomationTable = useReactTable({
    columns,
    data: presentationAutomationTableData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="feature-comparison-section-header">Presentation Automation</div>
      <table className="feature-table">
        <tbody>
          {presentationAutomationTable.getRowModel().rows.map((row) => (
            <tr className="feature-table-row" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td className="feature-table-cell" key={cell.id}>
                  {cell.renderValue()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const WorkflowAutomationTable = () => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('label'),
    columnHelper.accessor('teamValue'),
    columnHelper.accessor('enterpriseValue'),
  ];

  const workflowAutomationTableData = [
    {
      label: 'Bulk generation',
      teamValue: '',
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Scheduling',
      teamValue: '',
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Subtemplates',
      teamValue: '',
      enterpriseValue: <GreenCheckmark />,
    },
  ];

  const workflowAutomationTable = useReactTable({
    columns,
    data: workflowAutomationTableData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="feature-comparison-section-header">Workflow Automation</div>
      <table className="feature-table">
        <tbody>
          {workflowAutomationTable.getRowModel().rows.map((row) => (
            <tr className="feature-table-row" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td className="feature-table-cell" key={cell.id}>
                  {cell.renderValue()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const AdministrationTable = () => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('label'),
    columnHelper.accessor('teamValue'),
    columnHelper.accessor('enterpriseValue'),
  ];

  const administrationTableData = [
    {
      label: 'Number of seats',
      teamValue: Constants.MATIK_TIERS.matik_team.userLimit,
      enterpriseValue: 'Custom',
    },
    {
      label: 'Template sharing',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Permissioning',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Usage analytics',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Bulk changes',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'SSO',
      teamValue: '',
      enterpriseValue: <GreenCheckmark />,
    },
  ];

  const administrationTable = useReactTable({
    columns,
    data: administrationTableData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="feature-comparison-section-header">Administration & Control</div>
      <table className="feature-table">
        <tbody>
          {administrationTable.getRowModel().rows.map((row) => (
            <tr className="feature-table-row" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td className="feature-table-cell" key={cell.id}>
                  {cell.renderValue()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const SupportTable = () => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('label'),
    columnHelper.accessor('teamValue'),
    columnHelper.accessor('enterpriseValue'),
  ];

  const supportTableData = [
    {
      label: 'Email support',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Chat support',
      teamValue: <GreenCheckmark />,
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Dedicated customer success manager',
      teamValue: '',
      enterpriseValue: <GreenCheckmark />,
    },
    {
      label: 'Implementation support',
      teamValue: '',
      enterpriseValue: <GreenCheckmark />,
    },
  ];

  const supportTable = useReactTable({
    columns,
    data: supportTableData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="feature-comparison-section-header">Support</div>
      <table className="feature-table">
        <tbody>
          {supportTable.getRowModel().rows.map((row) => (
            <tr className="feature-table-row" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td className="feature-table-cell" key={cell.id}>
                  {cell.renderValue()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default FeatureComparison;
