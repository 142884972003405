import React from 'react';
import { Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import AddRemoveButtons from '../../../shared/AddRemoveButtons';
import { Select } from '../../../shared/FormSelect';
import { cloneDeep } from 'lodash';

function ApiSorts({ sortsArray, allFields, isReadOnly, onSortsUpdate }) {
  const sorts = sortsArray || [];

  const addSort = (e) => {
    e.preventDefault();
    let updatedSortsArray = cloneDeep(sortsArray) || [];
    let defaultSort = allFields[0] || '';
    updatedSortsArray.push(defaultSort + ' asc');
    onSortsUpdate(updatedSortsArray);
  };

  const removeSort = (e, idx) => {
    e.preventDefault();
    let updatedSortsArray = cloneDeep(sortsArray);
    let sorts = [];
    for (let i = 0; i < updatedSortsArray.length; i++) {
      if (i !== idx) {
        sorts.push(updatedSortsArray[i]);
      }
    }
    updatedSortsArray = sorts;
    onSortsUpdate(updatedSortsArray);
  };

  const onSortFieldChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const fieldName = obj.value;
      let updatedSortsArray = cloneDeep(sortsArray);
      const sortDirection = getSortDirection(updatedSortsArray[idx]);
      updatedSortsArray[idx] = fieldName + ' ' + sortDirection;
      onSortsUpdate(updatedSortsArray);
    }
  };

  const onSortDirChange = (obj, action, idx) => {
    if (action.action === 'select-option') {
      const direction = obj.value;
      let updatedSortsArray = cloneDeep(sortsArray);
      const sortField = getSortField(updatedSortsArray[idx]);
      updatedSortsArray[idx] = sortField + ' ' + direction;
      onSortsUpdate(updatedSortsArray);
    }
  };

  const getSortField = (sortString) => {
    const delimiterIdx = sortString.lastIndexOf(' ');
    if (delimiterIdx >= 0) {
      return sortString.substr(0, delimiterIdx);
    }
    return sortString;
  };

  const getSortDirection = (sortString) => {
    const delimiterIdx = sortString.lastIndexOf(' ');
    if (delimiterIdx >= 0) {
      return sortString.substr(delimiterIdx + 1);
    }
    return 'asc';
  };

  let body = (
    <a href="#dummy" onClick={addSort}>
      Add Sorting
    </a>
  );

  if (sorts && sorts.length > 0) {
    body = sorts.map((sort, idx) => {
      const sortField = getSortField(sort);
      const sortDir = getSortDirection(sort);
      return renderSort(sortField, sortDir, sorts, idx);
    });
  }

  return (
    <Form.Field className="mbl">
      <Form.Label>Result Sorting</Form.Label>
      <Form.Help>Select fields to order by.</Form.Help>
      <Form.Control>{body}</Form.Control>
    </Form.Field>
  );

  function renderSort(sortField, sortDir, sorts, idx) {
    return (
      <Form.Field kind="group" key={`${idx}_sort`}>
        <AddRemoveButtons
          idx={idx}
          includeAdd={idx === sorts.length - 1}
          removeEntity={removeSort}
          addEntity={addSort}
          isReadOnly={isReadOnly}
        >
          <Form.Control style={{ flex: '5.5 1' }}>
            <Select
              value={{ label: sortField, value: sortField }}
              classNamePrefix="matik-select"
              isDisabled={isReadOnly}
              onChange={(obj, action) => onSortFieldChange(obj, action, idx)}
              aria-label="Select Field to Order By"
              options={allFields.map((field) => ({ label: field, value: field }))}
            />
          </Form.Control>
          <Form.Control style={{ flex: '1 1' }}>
            <Select
              aria-label="Select Sort Direction"
              isDisabled={isReadOnly}
              value={{ label: sortDir.toUpperCase(), value: sortDir }}
              classNamePrefix="matik-select"
              onChange={(obj, action) => onSortDirChange(obj, action, idx)}
              options={[
                { label: 'ASC', value: 'asc' },
                { label: 'DESC', value: 'desc' },
              ]}
            />
          </Form.Control>
        </AddRemoveButtons>
      </Form.Field>
    );
  }
}

ApiSorts.propTypes = {
  sortsArray: PropTypes.array,
  allFields: PropTypes.array,
  isReadOnly: PropTypes.bool,
  onSortsUpdate: PropTypes.func,
};

export default ApiSorts;
