import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WithFilterListHeader from '../../shared/WithFilterListHeader';
import FullPageList from '../../shared/FullPageList';
import { ContentNameCell, DataSourceTypeCell } from '../../shared/FullPageListCells';
import moment from 'moment';
import { Box } from 'react-bulma-components';
import { withRouter } from 'react-router-dom';
import Constants from '../../Constants';
import { createColumnHelper } from '@tanstack/react-table';
import localeDateFormatterUtils from 'lib/localeDate';

const ListWithFilterHeader = WithFilterListHeader(FullPageList);

class DataSourcesList extends Component {
  render() {
    const columnHelper = createColumnHelper();

    const columns = [
      columnHelper.accessor('type', {
        header: 'Type',
        cell: DataSourceTypeCell,
        meta: {
          width: '70px',
        },
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ContentNameCell,
      }),
      columnHelper.accessor(
        (d) => {
          const locale = localeDateFormatterUtils.getUserLocale();
          const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
          return moment(d.updated_on).format(localeDateFormat);
        },
        {
          id: 'updated_on',
          header: 'Last Updated',
          meta: {
            width: '145px',
          },
        },
      ),
    ];

    return (
      <Box className="is-shadowless is-paddingless is-fullheight">
        <ListWithFilterHeader
          entities={this.props.dataSources}
          newEntityUrl={this.props.newEntityUrl}
          columns={columns}
          isSidebar={true}
          title="Your Data Sources"
          buttonClass="is-secondary"
          selectedRow={this.props.selectedDataSource}
          type="data_source"
          scrollInElement={true}
          loading={this.props.isPaginating}
          newEntityButton="Add Data Source"
          filterPlaceholder="Search Data Sources"
          pagination={this.props.pagination}
          fetchItems={this.fetchItems}
          searchState={this.props.searchState}
          searchAttributes={this.props.searchAttributes}
          hideTopPagination={true}
          onRowClick={this.onRowClick}
          isSearching={this.props.isSearching}
          displayFilterHeader={true}
        />
      </Box>
    );
  }
  onRowClick = (dataSourceId) => {
    this.props.history.push(`/data_sources/${dataSourceId}`);
  };

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    this.props.fetchItems(offset, Constants.PAGE_SIZE, sort);
  };
}

DataSourcesList.propTypes = {
  dataSources: PropTypes.array,
  fetchItems: PropTypes.func,
  history: PropTypes.object,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  onDataSourceSelect: PropTypes.func,
  newEntityUrl: PropTypes.string,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedDataSource: PropTypes.object,
};

export default withRouter(DataSourcesList);
