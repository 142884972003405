import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContentNameCell, ContentShortTypeCell } from '../../shared/FullPageListCells';
import moment from 'moment';
import FullPageList from '../../shared/FullPageList';
import { withRouter } from 'react-router-dom';
import Constants from '../../Constants';
import WithFilterListHeader from '../../shared/WithFilterListHeader';
import { Box } from 'react-bulma-components';
import { createColumnHelper } from '@tanstack/react-table';
import localeDateFormatterUtils from 'lib/localeDate';

const PageWithFilterHeader = WithFilterListHeader(FullPageList);

class DynamicContentList extends Component {
  render() {
    const columnHelper = createColumnHelper();

    const columns = [
      columnHelper.accessor('dynamic_content_type', {
        header: 'Type',
        cell: ContentShortTypeCell,
        meta: {
          width: '70px',
        },
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ContentNameCell,
      }),
      columnHelper.accessor(
        (d) => {
          const locale = localeDateFormatterUtils.getUserLocale();
          const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
          return moment(d.updated_on).format(localeDateFormat);
        },
        {
          id: 'updated_on',
          header: 'Last Updated',
          meta: {
            width: '145px',
          },
        },
      ),
    ];

    let newEntityUrl;
    let title;
    let filterPlaceholder;
    if (this.props.listContext === 'template') {
      columns.pop();
      newEntityUrl = null;
      title = null;
      filterPlaceholder = 'Search';
    } else {
      newEntityUrl = '/dynamic_content/new';
      title = 'Your Dynamic Content';
      filterPlaceholder = 'Search Dynamic Content';
    }
    return (
      <Box className="is-shadowless is-paddingless dynamic-content-sidebar">
        <PageWithFilterHeader
          entities={this.props.dynamicContent}
          isSidebar={true}
          newEntityUrl={newEntityUrl}
          type="dynamic_content"
          buttonClass="is-secondary"
          onRowClick={this.onRowClick}
          hideTopPagination={true}
          selectedRow={this.props.selectedDynamicContent}
          columns={columns}
          title={title}
          loading={this.props.isPaginating}
          pagination={this.props.pagination}
          filterPlaceholder={filterPlaceholder}
          searchState={this.props.searchState}
          searchAttributes={this.props.searchAttributes}
          fetchItems={this.fetchItems}
          scrollInElement={true}
          isSearching={this.props.isSearching}
          displayFilterHeader={true}
          emailEditorType={this.props.emailEditorType || ''}
        />
      </Box>
    );
  }

  onRowClick = (contentId) => {
    this.props.history.push(`/dynamic_content/${contentId}`);
  };

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    this.props.fetchItems(offset, Constants.PAGE_SIZE, sort);
  };
}

DynamicContentList.propTypes = {
  dynamicContent: PropTypes.array,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  history: PropTypes.object,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedDynamicContent: PropTypes.object,
  listContext: PropTypes.string,
  emailEditorType: PropTypes.string,
};

export default withRouter(DynamicContentList);
