import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FullPageList from './FullPageList';
import SmallLoader from './SmallLoader';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import edit_icon from '../../svg/edit.svg';
import Icon from '../lib/Icon';
import { createColumnHelper } from '@tanstack/react-table';

const WithSelectedElement = (WrappedComponent) => {
  return class Inner extends Component {
    static propTypes = {
      loading: PropTypes.bool,
      isReadOnly: PropTypes.bool,
      startExpanded: PropTypes.bool,
      currentEntity: PropTypes.object,
      columns: PropTypes.array,
      onRowClick: PropTypes.func,
      onExpandClick: PropTypes.func,
      ancestorWantsToExpand: PropTypes.bool,
      closeSidepane: PropTypes.func,
      ui: PropTypes.object,
      openSidepane: PropTypes.func,
      toggleIsExpanded: PropTypes.func,
    };

    constructor(props) {
      super(props);
      this.state = {
        isExpanded: this.props.startExpanded,
      };
      this.ref = React.createRef();
      this.columnHelper = createColumnHelper();
    }

    componentDidUpdate() {
      if (!this.props.ancestorWantsToExpand && this.state.isExpanded) {
        this.setState({ isExpanded: false });
      }
    }

    componentWillUnmount() {
      if (!location.pathname.startsWith('/templates/')) {
        this.props.closeSidepane();
      }
    }

    createCollapsedColumns = () => {
      const downChevronCell = this.props.isReadOnly
        ? () => <span />
        : () => (
            <>
              <button className="is-flex is-vertical-centered icon-button">
                <img
                  src={edit_icon}
                  width="16px"
                  alt="Parameter Type"
                  data-tooltip-id="matik-tooltip"
                  data-tooltip-content="Edit"
                  className="edit-condition mrm"
                  ref={this.ref}
                />
              </button>
              <button
                className="is-flex is-vertical-centered icon-button cusror-pointer"
                data-tooltip-content="Select"
                data-tooltip-id="matik-tooltip"
              >
                <Icon name="chevron_down" />
              </button>
            </>
          );

      const collapsedColumns = this.props.columns
        .filter((column) => column.accessorKey !== 'updated_on')
        .map((column) => {
          const { ...rest } = column;
          return this.columnHelper.accessor(column.accessorKey, {
            ...rest,
            header: () => null, // Remove header
          });
        });

      collapsedColumns.push(
        this.columnHelper.display({
          id: 'down_chevron',
          cell: downChevronCell,
          meta: { justifyContent: 'flex-end' },
        }),
      );

      return collapsedColumns;
    };

    render() {
      if (this.props.loading) {
        return <SmallLoader />;
      }

      // At the moment, Expanded view doesn't make much sense in read-only mode
      if (this.state.isExpanded && !this.props.isReadOnly) {
        return <WrappedComponent {...this.props} onRowClick={!this.props.isReadOnly && this.onRowClick} />;
      }

      const collapsedColumns = this.createCollapsedColumns();

      return (
        <FullPageList
          entitiesToRender={[this.props.currentEntity]}
          columns={collapsedColumns}
          onHeaderClick={!this.props.isReadOnly && this.onExpandClick}
        />
      );
    }

    onRowClick = (id) => {
      this.setState({ isExpanded: false });
      if (this.props.onRowClick) {
        return this.props.onRowClick(id);
      }
    };

    onExpandClick = (e) => {
      e.preventDefault();
      if (e.target === this.ref.current) {
        if (this.props.ui.sidepane) {
          window.open(`/dynamic_content/${this.props.currentEntity.id}`, '_blank').focus();
        } else {
          this.props.openSidepane({ ...this.props.currentEntity, entityType: 'dynamic_content' }, 'template-sidepane');
        }
      } else {
        this.props.toggleIsExpanded(e);
        this.setState({ isExpanded: true });
        if (this.props.onExpandClick) {
          return this.props.onExpandClick();
        }
      }
    };
  };
};

export default compose(connect(mapUiStateToProps, mapDispatchToProps), WithSelectedElement);
