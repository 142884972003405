import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { Select } from '../../../shared/FormSelect';
import Constants from '../../../Constants';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import SimpleReturnFields from './SimpleReturnFields';
import { cloneDeep } from 'lodash';
import ApiFilters from './ApiFilters';
import ApiSorts from './ApiSorts';
import ApiLimit from './ApiLimit';

function ProductboardForm(props) {
  const dynamicContentContext = useContext(DynamicContentContext);
  const objectOptions = [
    { label: 'Feature', value: '/features' },
    { label: 'Feature Status', value: '/feature-statuses' },
  ];
  const fieldOptions = {
    '/features': [
      'id',
      'name',
      'description',
      'type',
      'status.id',
      'status.name',
      'owner.email',
      'links.self',
      'links.html',
      'timeframe.startDate',
      'timeframe.endDate',
      'timeframe.granularity',
      'createdAt',
      'updatedAt',
    ],
    '/feature-statuses': ['id', 'name', 'completed'],
  };

  if (!props.queryObj?.query) {
    const updatedQueryObj = cloneDeep(props.queryObj);
    updatedQueryObj.query = { obj: objectOptions[0].value };
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  }

  if (!props.queryObj.returnFieldsByName) {
    const updatedQueryObj = cloneDeep(props.queryObj);
    updatedQueryObj['returnFieldsByName'] = {};
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  }

  const selectObject = (obj, action) => {
    if (action.action === 'select-option') {
      const value = obj.value;
      const updatedQueryObj = cloneDeep(props.queryObj);
      updatedQueryObj.query.obj = value;
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    }
  };

  const objectValue =
    objectOptions.filter((option) => option.value === props.queryObj.query?.obj)[0] || objectOptions[0];
  const selectedFieldOptions = fieldOptions[objectValue?.value];

  const returnFieldsArray = props.queryObj.returnFieldsByName ? Object.keys(props.queryObj?.returnFieldsByName) : [];
  const returnFieldMapping = props.queryObj.query?.return_field_mapping || {};

  const onReturnFieldAdd = (addedOption) => {
    const updatedQueryObj = cloneDeep(props.queryObj);
    updatedQueryObj.returnFieldsByName[addedOption.value] = true;
    updatedQueryObj.query.fields = [...returnFieldsArray, addedOption.value];
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onReturnFieldSelectAll = (options) => {
    const returnFields = options.map((fieldOption) => fieldOption.value);
    const updatedQueryObj = cloneDeep(props.queryObj);
    updatedQueryObj.returnFieldsByName = Object.fromEntries(returnFields.map((fieldName) => [fieldName, true]));
    updatedQueryObj.query.fields = returnFields;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onReturnFieldRemove = (removedOption) => {
    const updatedQueryObj = cloneDeep(props.queryObj);
    delete updatedQueryObj.returnFieldsByName[removedOption.value];
    updatedQueryObj.query.fields = returnFieldsArray.filter((removedValue) => removedValue !== removedOption.value);
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onReturnFieldClear = () => {
    const updatedQueryObj = cloneDeep(props.queryObj);
    updatedQueryObj.returnFieldsByName = {};
    updatedQueryObj.query.fields = [];
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onReturnFieldsMappingUpdate = (returnField, newAlias) => {
    const updatedQueryObj = cloneDeep(props.queryObj);
    updatedQueryObj.query.return_field_mapping = { ...returnFieldMapping, [returnField]: newAlias };
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  let noOptionsMessage = 'No Options Found';

  const onFilterUpdate = (filterArray) => {
    const updatedQueryObj = cloneDeep(props.queryObj);
    updatedQueryObj.query.filters = filterArray;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onSortsUpdate = (sortsArray) => {
    const updatedQueryObj = cloneDeep(props.queryObj);
    updatedQueryObj.query.sorts = sortsArray;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  const onLimitUpdate = (limitValue) => {
    const updatedQueryObj = cloneDeep(props.queryObj);
    updatedQueryObj.query.limit = limitValue;
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  return (
    <>
      <Form.Field>
        <Form.Label>Productboard Object</Form.Label>
        <Form.Help>Select Productboard Object to return</Form.Help>
        <Form.Control>
          <Select
            aria-label="Select Productboard Object"
            classNamePrefix="matik-select"
            className="matik-select-container"
            isDisabled={dynamicContentContext.isReadOnly}
            value={objectValue}
            name="productboard-object"
            onChange={(obj, action) => selectObject(obj, action)}
            placeholder="Select Productboard Object"
            options={objectOptions}
          />
        </Form.Control>
      </Form.Field>
      {props.queryObj?.query && (
        <>
          <SimpleReturnFields
            allFields={selectedFieldOptions}
            returnFields={returnFieldsArray}
            returnFieldMapping={returnFieldMapping}
            onReturnFieldAdd={onReturnFieldAdd}
            onReturnFieldRemove={onReturnFieldRemove}
            onReturnFieldSelectAll={onReturnFieldSelectAll}
            onReturnFieldClear={onReturnFieldClear}
            onReturnFieldMappingUpdate={onReturnFieldsMappingUpdate}
            dataSourceName="ProductBoard"
            removeToolTip="Remove this field entirely."
            noOptionsMessage={noOptionsMessage}
          />
          <ApiFilters
            filterArray={props.queryObj.query.filters}
            allFields={selectedFieldOptions}
            supportedOperators={Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE[Constants.DATA_SOURCE_TYPES.productboard]}
            inputs={dynamicContentContext.existingInputs}
            isReadOnly={dynamicContentContext.isReadOnly}
            noOptionsMessage={noOptionsMessage}
            isInputPopoverDisabled={props.isInputPopoverDisabled}
            onFilterUpdate={onFilterUpdate}
          />
          <ApiSorts
            allFields={selectedFieldOptions}
            sortsArray={props.queryObj.query.sorts}
            isReadOnly={dynamicContentContext.isReadOnly}
            onSortsUpdate={onSortsUpdate}
          />
          <ApiLimit
            limitValue={props.queryObj.query.limit}
            isReadOnly={dynamicContentContext.isReadOnly}
            onLimitUpdate={onLimitUpdate}
          />
        </>
      )}
    </>
  );
}

ProductboardForm.propTypes = {
  inputsInQueryString: PropTypes.object,
  isInputPopoverDisabled: PropTypes.bool,
  queryObj: PropTypes.object,
};

export default ProductboardForm;
