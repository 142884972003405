import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import UpdateTemplateFileModal from './UpdateTemplateFileModal';
import TestPresentationModal from './TestPresentationModal';
import API from '../../../lib/api';
import LongRequest from '../../../lib/longRequest';
import { MAlert } from '../../shared/Alerts';
import AccessManager from '../../../lib/AccessManager';
import dynamicContent from '../../../lib/dynamicContent';
import { UserContext } from '../../UserContext';
import AccessModal from '../../shared/AccessModal';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';
import { mapDispatchToProps as mapUiDispatchToProps } from '../../../redux/ui/dispatchers';
import PresentationTemplateBody from './PresentationTemplateBody';
import DocumentTemplateBody from './DocumentTemplateBody';
import EmailTemplateMain from '../email/EmailTemplateMain';
import PageLoader from '../../shared/PageLoader';
import utils from '../../../lib/utils';
import { isEmpty, isEqual } from 'lodash';
import withPresentationContextConsumer from '../../shared/presentations/WithPresentationContextConsumer';
import Constants from 'components/Constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import DomainAuthModal from 'components/enterprise_settings/DomainAuthModal';
import Banner, { toastBanner } from '../../shared/Banner';
import TemplatesArchiveAction from './TemplatesArchiveAction';
import TemplatesDeleteAction from './TemplatesDeleteAction';
import TemplateSidepane from 'components/producer/templates/TemplateSidepane';
import {
  useTemplateContent,
  useTemplateLinkedObjectStatus,
  useTemplateMutator,
  useTemplateResyncStatus,
} from 'lib/hooks/useTemplate';
import useAccesses from 'lib/hooks/useAccess';
import { useAllDynamicContentById } from '../../../lib/hooks/useDynamicContent';
class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownIsActive: false,
      showRefreshTemplateLoading: false,
      status: null,
      isStatusFetching: false,
      inputValues: {},
      enterprise: null,
      emailPreviewValues: {},
      isPreviewLoading: false,
      isTemplateTest: false,
    };
  }

  componentDidMount() {
    this._onMountOrUpdate();
    this.setState({ enterprise: this.context?.user?.enterprise });
    if (
      this.props.presentationContext &&
      this.props.presentationContext.regenerate &&
      this.props.presentationContext.presentationToRegenerate
    ) {
      const contextInputValues = this.props.presentationContext.getInputValues();
      this.setInputValuesFromPresentationContext(contextInputValues);
    }
  }

  componentDidUpdate() {
    this._onMountOrUpdate();
    if (
      this.props.presentationContext &&
      this.props.presentationContext.regenerate &&
      this.props.presentationContext.presentationToRegenerate
    ) {
      const contextInputValues = this.props.presentationContext.getInputValues();
      if (!isEqual(contextInputValues, this.state.inputValues)) {
        this.setInputValuesFromPresentationContext(contextInputValues);
      }
    }
  }

  componentWillUnmount() {
    if (
      this.props.currentTemplate?.id &&
      this.props.presentationContext &&
      this.props.presentationContext.regenerate &&
      this.props.presentationContext.presentationToRegenerate
    ) {
      this.props.presentationContext.clearPresentationContext();
    }

    if (this.props.ui.sidepane) {
      this.props.closeSidepane();
    }
  }

  _onMountOrUpdate() {
    this.fetchStatusIfNeeded();
  }

  _openUnsavedChangesModal = (onConfirm, content) => {
    this.props.openUnsavedChangesModal({
      title: 'Abandon changes?',
      onConfirm: onConfirm,
      primaryButton: 'Continue',
      content: content,
      name: 'template-sidepane',
    });
  };

  _handleContentClick = (content) => {
    const ui = this.props.ui;
    if (
      !ui.sidepane ||
      ui.sidepane?.content.entityType === 'input' ||
      ui.sidepane?.content.matchingContent?.id !== content.matchingContent?.id
    ) {
      if (ui.isChanged) {
        this._openUnsavedChangesModal('openSidepane', content);
      } else {
        this.props.openSidepane(content, 'template-sidepane');
      }
    } else if (
      ui.sidepane?.content.matchingContent?.id == content.matchingContent?.id ||
      (ui.sidepane?.content.entityType === 'dynamic_content' && !ui.sidepane?.content.matchingContent)
    ) {
      if (ui.isChanged) {
        this._openUnsavedChangesModal('closeSidepane', content);
      } else {
        this.props.closeSidepane();
      }
    }
  };

  _handleInputClick = (e, input) => {
    e.preventDefault();
    const ui = this.props.ui;
    if (ui.modal) {
      this.props.closeModal();
      this.props.closeSidepane();
    }
    if (!ui.sidepane || ui.sidepane.content.id !== input.id) {
      if (ui.isChanged) {
        this._openUnsavedChangesModal('openSidepane', input);
      } else {
        this.props.openSidepane(input, 'template-sidepane');
      }
    } else if (ui.sidepane.content.id === input.id) {
      if (ui.isChanged) {
        this._openUnsavedChangesModal('closeSidepane');
      } else {
        this.props.closeSidepane();
      }
    }
  };

  render() {
    const currentTemplate = this.props.currentTemplate;
    if (!currentTemplate) {
      return null;
    }
    const attachedTemplate = this.props.attachedTemplate;

    const allDynamicContent = this.props.dynamicContentByName || {};
    if (this.props.templateContent) {
      dynamicContent.setAllDynamicContentByName(this.props.templateContent, allDynamicContent);
    }
    if (this.props.attachedTemplateContent) {
      dynamicContent.setAllDynamicContentByName(this.props.attachedTemplateContent, allDynamicContent);
    }

    const accessObj = new AccessManager(currentTemplate.id, this.props.accessesByTemplateId, this.context.user);
    const canEdit = accessObj.can('edit');

    const templateType =
      currentTemplate.source_type === 'email'
        ? 'email'
        : currentTemplate.source_type === 'google_docs'
        ? 'document'
        : 'presentation';
    const emailTab =
      this.props.presentationContext &&
      this.props.presentationContext.regenerate &&
      this.props.presentationContext.presentationToRegenerate
        ? 'send & schedule'
        : 'edit';

    const sendgridInfo = this.state.enterprise?.integrations?.find((i) => i.name === 'sendgrid');

    const updatePresentationTitle =
      currentTemplate.source_type === 'powerpoint_365'
        ? 'Updating PowerPoint Presentation'
        : 'Updating Google Presentation';

    return (
      <div className="wrapper">
        <div className="main phn">
          {templateType === 'email' ? (
            <EmailTemplateMain
              currentTemplate={currentTemplate}
              attachedTemplate={attachedTemplate}
              canEdit={canEdit}
              accessesByTemplateId={this.props.accessesByTemplateId}
              onTemplateUpdateWithServerCall={this.props.onTemplateUpdateWithServerCall}
              onTemplateDelete={this.onTemplateDelete}
              onTemplateArchive={this.onTemplateArchive}
              allDynamicContent={allDynamicContent}
              onPresentationCreate={this.props.onPresentationCreate}
              currentTab={emailTab}
              openAuthModal={this.openAuthModal}
              sendgridInfo={sendgridInfo}
              enterprise={this.state.enterprise}
              onTemplateTest={this.onTemplateTest}
              emailPreviewValues={this.state.emailPreviewValues}
              onContentClick={this._handleContentClick}
              onInputClick={this._handleInputClick}
              isPreviewLoading={this.state.isPreviewLoading}
            />
          ) : templateType === 'document' ? (
            <DocumentTemplateBody
              currentTemplate={currentTemplate}
              onTemplateTest={this.onTemplateTest}
              onTemplateDelete={this.onTemplateDelete}
              onTemplateArchive={this.onTemplateArchive}
              onTemplateUpdateWithServerCall={this.props.onTemplateUpdateWithServerCall}
              onUpdateTemplateFile={this.onUpdateTemplateFile}
              canEdit={canEdit}
              allDynamicContent={allDynamicContent}
              accessesByTemplateId={this.props.accessesByTemplateId}
              status={this.state.status}
              onContentClick={this._handleContentClick}
              onInputClick={this._handleInputClick}
              onPresentationCreate={this.props.onPresentationCreate}
            />
          ) : (
            <PresentationTemplateBody
              currentTemplate={currentTemplate}
              onTemplateUpdateWithServerCall={this.props.onTemplateUpdateWithServerCall}
              onUpdateTemplateFile={this.onUpdateTemplateFile}
              canEdit={canEdit}
              onTemplateDelete={this.onTemplateDelete}
              onTemplateArchive={this.onTemplateArchive}
              onTemplateTest={this.onTemplateTest}
              allDynamicContent={allDynamicContent}
              status={this.state.status}
              accessesByTemplateId={this.props.accessesByTemplateId}
              onContentClick={this._handleContentClick}
              onInputClick={this._handleInputClick}
              onPresentationCreate={this.props.onPresentationCreate}
            />
          )}
          <UpdateTemplateFileModal
            onTemplateInvalidate={this.props.onTemplateInvalidate}
            template={currentTemplate}
            show={this.props.ui.modal?.name === 'updateTemplateFileModal'}
            onClose={this.onUpdateTemplateFileModalClose}
          />
          <TestPresentationModal
            allDynamicContent={allDynamicContent}
            show={this.props.ui.modal?.name === 'testPresentationModal'}
            template={currentTemplate}
            onPresentationCreate={this.onTestPresentationCreate}
            inputValues={this.state.inputValues}
            onClose={this.props.closeModal}
            onEmailPreview={this.onEmailPreview}
            isTemplateTest={this.state.isTemplateTest}
            onEmailPreviewError={this.onEmailPreviewError}
          />
          {!this.props.ui.sidepane && (
            <AccessModal
              accesses={new AccessManager(currentTemplate.id, this.props.accessesByTemplateId, this.context.user)}
              item={currentTemplate}
              show={this.props.ui.modal?.name === 'accessModal'}
            />
          )}
          <DomainAuthModal
            show={this.props.ui.fullScreenModal?.name === 'domainAuthModal'}
            onClose={this.props.closeFullScreenModal}
            enterprise={this.state.enterprise}
            onEnterpriseUpdate={this.onEnterpriseUpdate}
            inTemplatePage={true}
            templateToastBanner={this.authToastBanner}
          />
          <TemplateSidepane className="template-sidepane" template={currentTemplate} />
          <PageLoader title={updatePresentationTitle} isActive={this.state.showRefreshTemplateLoading} />
        </div>
      </div>
    );
  }

  onEmailPreview = (emailPreviewValues, dynamicContent) => {
    const updatedEmailPreviewValues = Object.assign({}, this.state.emailPreviewValues);
    for (let key in emailPreviewValues) {
      updatedEmailPreviewValues[key] = emailPreviewValues[key];
    }
    this.setState({ emailPreviewValues: updatedEmailPreviewValues, isPreviewLoading: true });
    if (dynamicContent?.length <= Object.values(updatedEmailPreviewValues)?.length) {
      this.setState({ isPreviewLoading: false });
    }
  };

  onEmailPreviewError = (err) => {
    this.setState({ isPreviewLoading: false });
    API.defaultError(err);
  };

  authToastBanner = () =>
    toastBanner(
      <Banner
        bannerType="error"
        text="Validation not sucessful!"
        sublineText="It can take up to 48 hours for the records to be verified."
        secondaryButtonLabel="Retry"
        onSecondaryButtonClick={this.openAuthModal}
      />,
    );

  openAuthModal = () => {
    this.props.openFullScreenModal('domainAuthModal');
  };

  onEnterpriseUpdate = (updatedEnterprise, notify = true) => {
    if (notify) {
      utils.notify('Enterprise updated');
    }
    this.setState({ enterprise: updatedEnterprise });
  };

  onTemplateDelete = (template) => {
    TemplatesDeleteAction(template, this.props.deleteTemplate, () => this.props.history.push('/templates'));
  };

  onTemplateArchive = (template) => {
    TemplatesArchiveAction(template, this.props.archiveTemplate, () => this.props.history.push('/templates'));
  };

  onTemplateTest = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ dropdownIsActive: false, isTemplateTest: true });
    this.props.openModal('testPresentationModal');
  };

  onTestPresentationCreate = async (presentation) => {
    let notifyContent = (
      <span>
        Presentation Created
        <a href={presentation.presentation_file.url}> View Here</a>
      </span>
    );
    utils.notify(notifyContent);
    if (presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT) {
      const response = await fetch(presentation.presentation_file.url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url; // eslint-disable-line
      link.download = presentation.presentation_file.original_filename;
      link.click();
    } else {
      window.open(presentation.presentation_file.url, '_blank');
    }
  };

  onUpdateTemplateFile = (e) => {
    if (e) {
      e.preventDefault();
    }
    let currentTemplate = this.props.currentTemplate;
    if (
      currentTemplate &&
      (currentTemplate.source_type === 'google_slides' || currentTemplate.source_type === 'powerpoint_365')
    ) {
      this.refreshDriveFile(
        currentTemplate,
        'Consider reducing the file size by using library slides or by downsizing assets.',
      );
      this.setState({ showRefreshTemplateLoading: true });
    } else if (currentTemplate && currentTemplate.source_type === 'google_docs') {
      this.refreshDriveFile(currentTemplate, 'Consider reducing the file size by downsizing assets.');
      this.setState({ showRefreshTemplateLoading: true });
    } else {
      this.props.openModal('updateTemplateFileModal');
    }
  };

  refreshDriveFile = (currentTemplate, tooLargeMessage) => {
    const data = {
      source: currentTemplate.source,
    };
    const onSuccess = (response, onComplete) => {
      if (response.data.template_status === 'done') {
        this.setState({ showRefreshTemplateLoading: false });
        this.props.onTemplateInvalidate(response.data.new_entity.id, response.data.new_entity);
        onComplete();
        if (response.data.new_entity && isEmpty(response.data.new_entity.pdf)) {
          MAlert(tooLargeMessage, 'File is too large', 'warning');
        }
      } else if (response.data.template_status === 'unknown') {
        // This may happen if there is a race condition: another user loads the templates page in between status checks
        onComplete();
        window.location.reload();
      }
    };
    const longRequest = new LongRequest('/templates');
    longRequest.put(currentTemplate.id, data, onSuccess, this.submitError, null);
  };

  fetchStatusIfNeeded = () => {
    let currentTemplate = this.props.currentTemplate ? [this.props.currentTemplate] : [];
    if (currentTemplate.length > 0) {
      const template = currentTemplate[0];
      if (!this.state.status && !this.state.isStatusFetching) {
        this.setState({ isStatusFetching: true });
        if (template.source_type === 'google_slides') {
          API.get(
            template.deleted
              ? `/templates/archives/${template.id}/google_status/`
              : `/templates/${template.id}/google_status/`,
            (response) => {
              this.setState({ status: response.data, isStatusFetching: false });
            },
            () => {}, // If this times out or fails, no need to alert the user.
          );
        } else if (template.source_type !== 'google_slides') {
          this.setState({ status: { status: 'success' } });
        }
      }
    }
  };

  setInputValuesFromPresentationContext = (contextInputValues) => {
    if (
      this.props.presentationContext.presentationToRegenerate.presentation_type !==
      Constants.TEMPLATE_SOURCE_TYPES.EMAIL
    ) {
      this.setState({ inputValues: contextInputValues });
      if (!this.props.flags?.showAdminGenerateTab) {
        this.onTemplateTest();
      }
    }
  };
}

Template.propTypes = {
  accessesByTemplateId: PropTypes.object,
  dynamicContentByName: PropTypes.object,
  history: PropTypes.object,
  onAccessDelete: PropTypes.func,
  onAccessesAdd: PropTypes.func,
  onTemplateInvalidate: PropTypes.func,
  onTemplateUpdateWithServerCall: PropTypes.func,
  deleteTemplate: PropTypes.func,
  archiveTemplate: PropTypes.func,
  flags: PropTypes.object,
  presentationContext: PropTypes.object,
  onPresentationCreate: PropTypes.func,
  ui: PropTypes.object,
  openModal: PropTypes.func,
  openSidepane: PropTypes.func,
  closeSidepane: PropTypes.func,
  openUnsavedChangesModal: PropTypes.func,
  closeModal: PropTypes.func,
  closeFullScreenModal: PropTypes.func,
  openFullScreenModal: PropTypes.func,
  currentTemplate: PropTypes.object,
  attachedTemplate: PropTypes.object,
  templateContent: PropTypes.object,
  attachedTemplateContent: PropTypes.object,
  fetchEmailFormContent: PropTypes.func,
};

Template.contextType = UserContext;

function mapStateToProps(state) {
  return {
    ...mapUiStateToProps(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...mapUiDispatchToProps(dispatch),
  };
}

const TemplateWrapper = (props) => {
  const { data: templateContent } = useTemplateContent(props.currentTemplate?.id, props.currentTemplate?.deleted);
  const { data: attachedTemplateContent } = useTemplateContent(
    props.attachedTemplate?.id,
    props.attachedTemplate?.deleted,
  );
  const { del: deleteTemplate, archive: archiveTemplate } = useTemplateMutator();

  const { invalidate: forceResyncCheck } = useTemplateResyncStatus(props.currentTemplate);
  const { invalidate: forceLinkedObjectCheck } = useTemplateLinkedObjectStatus(props.currentTemplate);

  const { data: accessesByTemplateId } = useAccesses('template');

  const { dynamicContentById } = useAllDynamicContentById();

  const dynamicContentByName = {};
  Object.values(dynamicContentById || {}).forEach((dynamicContent) => {
    dynamicContentByName[dynamicContent.name] = dynamicContent;
  });

  // Check the resync and linked-object status every time the template page is loaded
  useEffect(() => {
    forceResyncCheck();
    forceLinkedObjectCheck();
  }, []);

  return (
    <Template
      {...props}
      deleteTemplate={deleteTemplate}
      archiveTemplate={archiveTemplate}
      templateContent={templateContent}
      attachedTemplateContent={attachedTemplateContent}
      accessesByTemplateId={accessesByTemplateId}
      dynamicContentByName={dynamicContentByName}
    />
  );
};
TemplateWrapper.propTypes = {
  currentTemplate: PropTypes.object,
  attachedTemplate: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withLDConsumer()(withPresentationContextConsumer(TemplateWrapper))));
