import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, isEqual } from 'lodash';
import SlidesTemplateComponents from './SlidesTemplateComponents';
import inputs from '../../../lib/inputs';
import PageLoader from '../../shared/PageLoader';
import utils from '../../../lib/utils';
import InputsList from '../../../lib/inputsList';
import withPresentationContextConsumer from '../../shared/presentations/WithPresentationContextConsumer';
import { mapDispatchToProps as mapUiDispatchToProps } from '../../../redux/ui/dispatchers';
import withUserContext from '../../shared/WithUserContext';
import { MConfirm, MInsufficientPerms, MConnect, MInsufficientPermissions } from '../../shared/Alerts';
import { mapUiStateToProps } from '../../../redux/ui/stateMappers';
import { mapLibraryDispatchToProps } from '../../../redux/libraries/dispatchers';
import Swal from 'sweetalert2';
import { useTemplateContent, useTemplateMutator, useTemplateResyncStatus } from 'lib/hooks/useTemplate';
import API from '../../../lib/api';
import Constants from 'components/Constants';
import PresentationTemplateHeader from './PresentationTemplateHeader';
import PresentationCreator from 'components/consumer/PresentationCreator';
class PresentationTemplateBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChangingLibraryState: false,
      showRefreshTemplateLoading: false,
      selectedSlides: [],
      selectedSlidesBySlideNum: {},
      showSingleSlide: false,
      defaultTab: null,
      activeTab: props.presentationContext?.regenerate ? 'generate' : 'setup',
    };
  }

  componentDidMount() {
    if (
      this.props.currentTemplate &&
      this.props.presentationContext &&
      this.props.presentationContext.regenerate &&
      this.props.presentationContext.presentationToRegenerate
    ) {
      const contextSelectedSlideNums = this.props.presentationContext.getSelectedSlides(
        this.props.currentTemplate.slides,
      );
      this.setSelectedSlidesFromPresentationContext(contextSelectedSlideNums);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentTemplate &&
      this.props.presentationContext &&
      this.props.presentationContext.regenerate &&
      this.props.presentationContext.presentationToRegenerate
    ) {
      const contextSelectedSlideNums = this.props.presentationContext.getSelectedSlides(
        this.props.currentTemplate.slides,
      );
      if (!isEqual(contextSelectedSlideNums, this.state.selectedSlidesBySlideNum)) {
        this.setSelectedSlidesFromPresentationContext(contextSelectedSlideNums);
      }
    }

    if (!isEqual(prevProps.currentTemplate.slides, this.props.currentTemplate.slides)) {
      const newSlide = find(
        this.props.currentTemplate.slides,
        (slide) => slide.id === this.state.selectedSlides[0]?.id,
      );
      if (newSlide) {
        this.setState({
          selectedSlides: [newSlide, ...this.state.selectedSlides.slice(1)],
        });
      }
    }
  }

  componentWillUnmount() {
    if (
      this.props.template?.id &&
      this.props.presentationContext &&
      this.props.presentationContext.regenerate &&
      this.props.presentationContext.presentationToRegenerate
    ) {
      this.props.presentationContext.clearPresentationContext();
    }
  }

  render() {
    let offset = -1; // we want the idx of the slide
    let slideIdxByInputName = inputs.slideNumsByInputName(
      this.props.templateContent,
      this.props.currentTemplate.slides,
      this.props.allDynamicContent,
      offset,
    );

    const allOrderedInputs = new InputsList(
      this.getAllInputsOnTemplate(),
      this.props.currentTemplate.params_order,
    ).getSortedList();

    return (
      <>
        <PresentationTemplateHeader
          currentTemplate={this.props.currentTemplate}
          allOrderedInputs={allOrderedInputs}
          onTemplateUpdateWithServerCall={this.props.onTemplateUpdateWithServerCall}
          canEdit={this.props.canEdit}
          status={this.props.status}
          accessesByTemplateId={this.props.accessesByTemplateId}
          onTemplateTest={this.props.onTemplateTest}
          onTemplateDelete={this.props.onTemplateDelete}
          onTemplateArchive={this.props.onTemplateArchive}
          convertTemplate={this.convertTemplate}
          syncTemplate={this.syncTemplate}
          setIsLibrary={this.setIsLibrary}
          activeTab={this.state.activeTab}
          toggleTab={this.toggleTab}
        />
        {this.state.activeTab === 'generate' ? (
          <PresentationCreator onPresentationCreate={this.props.onPresentationCreate} hideHeader={true} />
        ) : (
          <SlidesTemplateComponents
            currentTemplate={this.props.currentTemplate}
            status={this.props.status}
            selectedSlides={this.state.selectedSlides}
            selectedSlidesBySlideNum={this.state.selectedSlidesBySlideNum}
            onUpdateTemplateFile={this.props.onUpdateTemplateFile}
            allDynamicContent={this.props.allDynamicContent}
            toggleSlideSelect={this.toggleSlideSelect}
            canEdit={this.props.canEdit}
            showSingleSlide={this.state.showSingleSlide}
            setShowSingleSlide={this.setShowSingleSlide}
            updateLoopIcons={this.updateLoopIcons}
            slideIdxByInputName={slideIdxByInputName}
            resetSelectedSlides={this.resetSelectedSlides}
            toggleAllSlides={this.toggleAllSlides}
            onContentClick={this.props.onContentClick}
            onInputClick={this.props.onInputClick}
            onInsertLibrarySlide={this.props.onInsertLibrarySlide}
            onRemoveLibrarySlide={this.props.onRemoveLibrarySlide}
          />
        )}
        <PageLoader title="Updating Google Presentation" isActive={this.state.showRefreshTemplateLoading} />
        <PageLoader title="Processing Request..." isActive={this.props.isWorking} />
      </>
    );
  }

  toggleTab = (label) => {
    this.setState({ activeTab: label.toLowerCase() });
  };

  setShowSingleSlide = (showSingleSlide) => {
    this.setState({ showSingleSlide });
  };

  getAllInputsOnTemplate = () => {
    let tagTree = utils.getDynamicContentTags(
      this.props.currentTemplate,
      this.props.templateContent,
      this.props.allDynamicContent,
    );

    return inputs.getAllInputs(
      tagTree.getTagNodes(),
      this.props.allDynamicContent,
      this.props.currentTemplate,
      this.props.templateContent,
      null,
    );
  };

  resetSelectedSlides = () => {
    this.setState({ selectedSlides: [], selectedSlidesBySlideNum: {} });
  };

  toggleSlideSelect = (e, slides, selected = undefined) => {
    const getSlideNum = (slide) => {
      const index = this.props.currentTemplate.slides.findIndex((s) => s.id === slide.id);
      return index + 1;
    };

    if (e) {
      e.preventDefault();
    }
    if (slides && !Array.isArray(slides)) {
      slides = [slides];
    }
    let selectedSlides = [...this.state.selectedSlides];
    let selectedSlidesBySlideNum = { ...this.state.selectedSlidesBySlideNum };
    if (selected) {
      selectedSlides = slides;
      selectedSlidesBySlideNum = {};
      slides.forEach((slide) => (selectedSlidesBySlideNum[getSlideNum(slide)] = true));
      this.setState({ selectedSlides, selectedSlidesBySlideNum });
    } else {
      slides.forEach((slide) => {
        const slideIsSelected = find(selectedSlides, (s) => s.id === slide.id);
        if (selectedSlides.length > 0 && slideIsSelected) {
          delete selectedSlidesBySlideNum[getSlideNum(slide)];
          let index = selectedSlides.indexOf(slide);
          selectedSlides.splice(index, 1);
        } else if (selected === undefined) {
          selectedSlidesBySlideNum[getSlideNum(slide)] = true;
          selectedSlides.push(slide);
          selectedSlides.sort((a, b) => (a.id > b.id ? 1 : -1));
        }
      });
      this.setState({ selectedSlides, selectedSlidesBySlideNum });
    }
  };

  toggleAllSlides = (val) => {
    const updatedSelectedSlidesBySlideNum = Object.assign({}, this.state.selectedSlidesBySlideNum);
    for (let slideNum in updatedSelectedSlidesBySlideNum) {
      updatedSelectedSlidesBySlideNum[slideNum] = val;
    }

    this.setState({ selectedSlides: [], selectedSlidesBySlideNum: updatedSelectedSlidesBySlideNum });
  };

  updateLoopIcons = () => {
    this.setState({ selectedSlides: [] });
  };

  setSelectedSlidesFromPresentationContext = (contextSelectedSlides) => {
    this.toggleAllSlides(false);
    let slides = [];
    for (let num = 1; num <= this.props.currentTemplate.slides.length; num++) {
      if (contextSelectedSlides[num]) {
        slides.push(this.props.currentTemplate.slides[num - 1]);
      }
    }
    if (!isEqual(slides, this.state.selectedSlides)) {
      this.setState({ selectedSlides: slides });
    }
    this.setState({ selectedSlidesBySlideNum: contextSelectedSlides });
  };

  setIsLibrary = (isLibrary) => {
    if (isLibrary) {
      this.props.setIsLibrary(this.props.currentTemplate.id, isLibrary);
    } else {
      const onBeforeOpen = () => {
        Swal.showLoading();
        API.get(`/templates/libraries/${this.props.currentTemplate.id}/templates/`, (response) => {
          if (response.data && response.data.length > 0) {
            const templateNames = response.data.map((template) => template.name);
            const updatedText = `You won't be able to use the slides in any templates and library slides from ${utils.convertArrayToEnglish(
              templateNames,
            )} will be removed`;
            Swal.update({ text: updatedText });
          }
          Swal.hideLoading();
        });
      };
      MConfirm(
        'Unpublish Library?',
        "You won't be able to use the slides in any templates",
        'warning',
        (confirmed) => {
          if (confirmed) {
            this.props.setIsLibrary(this.props.currentTemplate.id, isLibrary);
          }
        },
        'Unpublish',
        'Cancel',
        onBeforeOpen,
      );
    }
  };

  syncTemplate = () => {
    if (this.needsToConnect()) {
      return this.connectIntegration();
    }
    this.props.onUpdateTemplateFile();
    if (this.state.selectedSlides) {
      // this.hideSlides(e, this.state.selectedSlides);
    }
    this.props.hideResyncWarning();
  };

  convertTemplate = (folder) => {
    this.props.setIsWorking(true);
    const data = {
      folder_id: folder.id,
      drive_id: folder.parentReference.driveId,
    };
    const onSuccess = (response) => {
      const file = response.data?.new_entity;
      if (file) {
        this.props.onTemplateUpdateWithServerCall(file, Constants.CONSUMER_ROLE);
      }
    };
    API.post(`/templates/${this.props.currentTemplate.id}/convert_template/`, data, onSuccess, API.defaultError);
  };

  needsToConnect = () => {
    if (this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES) {
      const googleIntegration = utils.googleIntegration(this.props.userContext.user);
      return !googleIntegration || !googleIntegration.has_necessary_scopes;
    } else if (this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365) {
      const microsoftIntegration = utils.microsoftIntegration(this.props.userContext.user);
      return !microsoftIntegration || !microsoftIntegration.has_necessary_scopes;
    }
    return false;
  };

  connectIntegration = () => {
    if (this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES) {
      const title = 'Connect to Google Drive',
        message = 'You must grant Matik Google permissions to be able to connect to Google Drive',
        type = 'google',
        confirm = 'Connect to Google Drive';
      const callback = (confirm) => {
        confirm &&
          utils.connectGoogle(
            this.props.userContext.user,
            this.props.userContext.updateUser,
            this.syncTemplate,
            this.showPermsAlert,
          );
      };
      MConnect(title, message, type, callback, confirm);
    }
    if (this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365) {
      const title = 'Connect to Microsoft 365',
        message = 'You need to connect to Microsoft 365 before you can sync this PowerPoint presentation',
        type = 'microsoft',
        confirm = 'Connect to Microsoft 365';
      const callback = (confirm) => {
        confirm &&
          utils.connectMicrosoft(
            this.props.userContext.user,
            this.props.userContext.updateUser,
            this.syncTemplate,
            this.showMSPermsAlert,
          );
      };
      MConnect(title, message, type, callback, confirm);
    }
  };

  reconnectIntegration = () => {
    if (this.props.currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365) {
      utils.reconnectMicrosoft(
        this.props.userContext.user,
        this.props.userContext.updateUser,
        this.syncTemplate,
        this.showMSPermsAlert,
      );
      return;
    }
    utils.reconnectGoogle(
      this.props.userContext.user,
      this.props.userContext.updateUser,
      this.syncTemplate,
      this.showPermsAlert,
    );
  };

  showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message = 'In order to sync your template, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, this.reconnectIntegration);
  };

  showMSPermsAlert = () => {
    const title = 'Grant Microsoft permissions to Matik';
    const message =
      'To sync PowerPoint template from Office 365, you must grant Matik the permissions requested on the next screen.';
    MInsufficientPermissions(title, message, 'microsoft', this.reconnectIntegration);
  };
}

PresentationTemplateBody.propTypes = {
  allDynamicContent: PropTypes.object,
  currentTemplate: PropTypes.object,
  onTemplateDelete: PropTypes.func,
  onTemplateArchive: PropTypes.func,
  onTemplateTest: PropTypes.func,
  onTemplateUpdateWithServerCall: PropTypes.func,
  accessesByTemplateId: PropTypes.object,
  canEdit: PropTypes.bool,
  status: PropTypes.object,
  onTemplateUpdateFile: PropTypes.func,
  onUpdateTemplateFile: PropTypes.func,
  presentationContext: PropTypes.object,
  userContext: PropTypes.object,
  setIsLibrary: PropTypes.func,
  hideResyncWarning: PropTypes.func,
  isWorking: PropTypes.bool,
  setIsWorking: PropTypes.func,
  template: PropTypes.object,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
  templateContent: PropTypes.object,
  onInsertLibrarySlide: PropTypes.func,
  onRemoveLibrarySlide: PropTypes.func,
  openFullScreenModal: PropTypes.func,
  onPresentationCreate: PropTypes.func,
};

function mapStateToProps(state) {
  return Object.assign({}, mapUiStateToProps(state));
}

function mapDispatchToProps(dispatch) {
  return Object.assign({}, mapLibraryDispatchToProps(dispatch), mapUiDispatchToProps(dispatch));
}

const PresentationTemplateBodyWrapper = (props) => {
  const { data: templateContent } = useTemplateContent(props.currentTemplate?.id, props.currentTemplate?.deleted);
  const { hideWarning: hideResyncWarning } = useTemplateResyncStatus(props.currentTemplate);
  const { updateLibrary, insertLibrarySlide, removeLibrarySlide } = useTemplateMutator();

  const [isWorking, setIsWorking] = useState(false);

  const handleUpdateTemplate = (...args) => {
    setIsWorking(true);
    return props.onTemplateUpdateWithServerCall(...args).finally(() => setIsWorking(false));
  };

  const handleUpdateIsLibrary = (...args) => {
    return updateLibrary(...args).catch(API.defaultError);
  };

  const handleInsertLibrarySlide = (...args) => {
    setIsWorking(true);
    return insertLibrarySlide(...args)
      .catch(API.defaultError)
      .finally(() => setIsWorking(false));
  };

  const handleRemoveLibrarySlide = (...args) => {
    setIsWorking(true);
    return removeLibrarySlide(...args)
      .catch(API.defaultError)
      .finally(() => setIsWorking(false));
  };

  return (
    <PresentationTemplateBody
      {...props}
      isWorking={isWorking}
      setIsWorking={setIsWorking}
      onTemplateUpdateWithServerCall={handleUpdateTemplate}
      templateContent={templateContent}
      hideResyncWarning={hideResyncWarning}
      setIsLibrary={handleUpdateIsLibrary}
      onInsertLibrarySlide={handleInsertLibrarySlide}
      onRemoveLibrarySlide={handleRemoveLibrarySlide}
    />
  );
};
PresentationTemplateBodyWrapper.propTypes = {
  currentTemplate: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPresentationContextConsumer(withUserContext(PresentationTemplateBodyWrapper)));
