import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from '../../../Constants';
import utils from '../../../../lib/utils';
import {
  ContentNameCell,
  ContentShortTypeCell,
  DataSourceCell,
  getDataSourceName,
} from '../../../shared/FullPageListCells';
import moment from 'moment';
import { UserContext } from '../../../UserContext';
import API from '../../../../lib/api';
import { MAlert } from '../../../shared/Alerts';
import DynamicContentSelectEmptyWrapper from './DynamicContentSelectEmptyWrapper';
import dynamic_content_empty_icon from '../../../../images/dynamic_content_empty.png';
import { createColumnHelper } from '@tanstack/react-table';
import localeDateFormatterUtils from 'lib/localeDate';

class DynamicContentSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentContent: null,
      dynamicContent: [],
      startExpanded: !this.props.selectedDynamicContentId,
      isLoading: true,
      pagination: null,
    };
  }

  componentDidMount() {
    if (this.props.selectedDynamicContentId) {
      utils.getItemFromId(
        this.props.selectedDynamicContentId,
        this.state.currentContent,
        this.props.dynamicContentArr,
        '/dynamic_content/',
        (currentContent) => {
          this.setState({ currentContent, isLoading: false });
          this.fetchContent();
        },
        this.onFetchError,
      );
    } else {
      this.fetchContent();
    }
  }

  fetchContent = () => {
    API.fetchContent(
      '/dynamic_content/?lite=true',
      () => this.setState({ isLoading: true }),
      (dynamicContent, pagination) => {
        this.setState({ dynamicContent, pagination, isLoading: false });
      },
      0,
      Constants.PAGE_SIZE,
      null,
      `{"dynamic_content_type":[${Object.values(Constants.DynamicContentTypes)
        .filter((type) => type !== Constants.DynamicContentTypes.CONDITIONAL)
        .map((type) => `"${type}"`)
        .join(',')}]}`,
    );
  };

  onFetchError = (err) => {
    if (err.response) {
      if (err.response.status === 404) {
        MAlert(
          'Dynamic content not found: ' + err.message + '. Setting content to different content.',
          'Error',
          'error',
        );
      } else if (err.response.status === 400) {
        const message = err.response.data ? err.response.data.message : 'Malformed request';
        // eslint-disable-next-line no-console
        console.warn(err);
        MAlert(message);
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(err);
      MAlert('An unexpected error occurred. ' + err.message, 'Error', 'error');
    }

    if (!this.state.currentContent && this.props.dynamicContentArr) {
      this.setState({ currentContent: this.props.dynamicContentArr[0], isLoading: false });
      this.fetchContent();
    }
  };

  render() {
    // We need this getMethod for sorting, because React Table sorts by row.value. If we just have a cell for this
    // column, then React Table doesn't know how to sort it.
    const getMethod = (dynamicContent) => {
      if (dynamicContent.dynamic_content_type === 'conditional') {
        return '--';
      }

      return Constants.DYNAMIC_CONTENT_METHOD_DISPLAY[dynamicContent.dynamic_content_method]?.text;
    };

    const columnHelper = createColumnHelper();

    const columns = [
      columnHelper.accessor('dynamic_content_type', {
        header: 'Type',
        cell: ContentShortTypeCell,
        meta: {
          width: '70px',
        },
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ContentNameCell,
      }),
      columnHelper.accessor(getMethod, {
        id: 'dynamic_content_method',
        header: 'Method',
      }),
      columnHelper.accessor(getDataSourceName, {
        id: 'data_source_name',
        header: 'Data Source',
        cell: DataSourceCell,
      }),
      columnHelper.accessor(
        (d) => {
          const locale = localeDateFormatterUtils.getUserLocale();
          const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
          return moment(d.updated_on).format(localeDateFormat);
        },
        {
          id: 'updated_on',
          header: 'Last Updated',
        },
      ),
    ];

    const emptyInfo = {
      emptyImageUrl: dynamic_content_empty_icon,
      emptyPrompt: "You haven't created any dynamic content yet.",
      buttonText: 'Set up your first Content Piece',
      emptyOnClick: this.props.showNewContentForm,
    };

    return (
      <div className="has-background-white dynamic-content-list">
        <DynamicContentSelectEmptyWrapper
          withSelect={this.props.withSelect}
          currentEntity={this.state.currentContent}
          startExpanded={this.state.startExpanded}
          isReadOnly={this.props.isReadOnly}
          context="dynamic_content"
          entities={this.state.dynamicContent}
          columns={columns}
          onRowClick={this.onRowClick}
          title={this.props.title}
          pagination={this.state.pagination}
          fetchItems={this.fetchItems}
          hideTopPagination={true}
          loading={this.state.isLoading}
          entityType="dynamic_content"
          filterPlaceholder="Search Dynamic Content"
          buttonClass="is-primary"
          newEntityPhrase={this.props.newEntityPhrase}
          newEntityFunc={this.props.newEntityFunc}
          isLoading={this.state.isLoading}
          elements={this.state.dynamicContent}
          emptyInfo={emptyInfo}
          toggleIsExpanded={this.props.toggleIsExpanded}
          ancestorWantsToExpand={this.props.ancestorWantsToExpand}
          filtersAreShownInSidepane={this.props.filtersAreShownInSidepane}
        />
      </div>
    );
  }

  onRowClick = (id) => {
    this.setState({ isLoading: true });
    utils.getItemFromId(
      id,
      this.state.currentContent,
      this.state.dynamicContent,
      '/dynamic_content/',
      (content) => {
        this.props.onDynamicContentSelect(content);
        this.setState({ isLoading: false, currentContent: content });
      },
      API.defaultError,
    );
  };

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    API.fetchContent(
      '/dynamic_content/',
      () => this.setState({ isLoading: true }),
      (dynamicContent, pagination) => {
        this.setState({ dynamicContent, pagination, isLoading: false });
      },
      offset,
      Constants.PAGE_SIZE,
      sort,
      `{"dynamic_content_type":[${Object.values(Constants.DynamicContentTypes)
        .filter((type) => type !== Constants.DynamicContentTypes.CONDITIONAL)
        .map((type) => `"${type}"`)
        .join(',')}]}`,
    );
  };
}

DynamicContentSelect.propTypes = {
  dynamicContentArr: PropTypes.array,
  isReadOnly: PropTypes.bool,
  newEntityPhrase: PropTypes.string,
  newEntityFunc: PropTypes.func,
  onDynamicContentSelect: PropTypes.func,
  selectedDynamicContentId: PropTypes.number,
  title: PropTypes.string,
  withSelect: PropTypes.bool,
  showNewContentForm: PropTypes.func,
  toggleIsExpanded: PropTypes.func,
  ancestorWantsToExpand: PropTypes.bool,
  filtersAreShownInSidepane: PropTypes.bool,
};

DynamicContentSelect.contextType = UserContext;

export default DynamicContentSelect;
