import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from 'react-bulma-components';
import { mapAnalyticsUsersStateToProps } from '../../../redux/users/stateMappers';
import { mapUsersDispatchToProps } from '../../../redux/users/dispatchers';
import utils from '../../../lib/utils';
import FullPageList from '../../shared/FullPageList';
import { NameCell, RoleCell } from '../../shared/FullPageListCells';
import Constants from '../../Constants';
import { createColumnHelper } from '@tanstack/react-table';

class AnalyticsUserList extends Component {
  render() {
    const columnHelper = createColumnHelper();

    const columns = [
      columnHelper.accessor((d) => (d.name ? d.name : d.email), {
        id: 'name',
        header: utils.tableHeader('Name'),
        cell: NameCell,
      }),
      columnHelper.accessor((d) => (d.roles ? d.roles.join(', ') : '--'), {
        id: 'roles',
        header: utils.tableHeader('Roles'),
        cell: RoleCell,
      }),
      columnHelper.accessor((row) => (row.total_presentations ? row.total_presentations : 0), {
        id: 'presentation_count',
        header: utils.tableHeader('Presentations'),
      }),
      columnHelper.accessor((row) => (row.monthly_presentations_avg ? row.monthly_presentations_avg : 0), {
        id: 'avg_presentations',
        header: utils.tableHeader('Avg Presentations / Month'),
      }),
    ];

    return (
      <Box className="is-shadowless px-0">
        <FullPageList
          entitiesToRender={this.props.users}
          columns={columns}
          fetchItems={this.fetchItems}
          onRowClick={this.onRowClick}
          pagination={this.props.pagination}
        />
      </Box>
    );
  }

  componentDidMount() {
    this.props.fetchAnalyticsUsersIfNeeded();
  }

  fetchItems = (pageIndex, sort) => {
    const offset = pageIndex * Constants.PAGE_SIZE;
    this.props.fetchAnalyticsUsersIfNeeded(offset, Constants.PAGE_SIZE, sort, this.props.pagination.count);
  };
}

AnalyticsUserList.propTypes = {
  isFetching: PropTypes.bool,
  pagination: PropTypes.object,
  users: PropTypes.array,
  fetchAnalyticsUsersIfNeeded: PropTypes.func,
};

export default connect(mapAnalyticsUsersStateToProps, mapUsersDispatchToProps)(AnalyticsUserList);
